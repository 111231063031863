import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";


const resources = {
  en: {
    translation: {
        AccountEach1:"Account Setting",
        AccountEach2:"Select Image",
        AccountEach3:"Image has been uploaded",
        AccountEach4:"Nickname",
        AccountEach5:"Birth",
        AccountEach6:"Gender",
        AccountEach7:"Caption",
        AccountEach8:"Save",
        AccountEach9:"Create New Account",
        AccountEach10:"Delete Account",
        AccountEach11:"Please fill in the required fields",
        AccountEach12:"Year",
        AccountEach13:"Month",
        AccountEach14:"Day",
        AccountEach15:"Male",
        AccountEach16:"Female",
        AccountEach17:"Other",
        AccountEach18:"No Answer",
        AdsModal1:"Advertisement",
        AdsModal2:"Advertising Message",
        AdsModal3:"URL for your business site",
        AdsModal4:"Select Billing Type",
        AdsModal5:"Impression",
        AdsModal6:"Click",
        AdsModal7:"Target Frequency",
        AdsModal8:"No Image",
        AdsModal9:"I agreee to the Terms & Conditions",
        AdsModal10:"Add Picture",
        AdsModal11:"Preview",
        AdsModal12:"Preview",
        AdsModal13:"Submit",
        AdsModal14:"Submit",
        AdsModal15:"Submit",
        AdsModal16:"Priority Display Tag【Basic/Premium Plan Only】",
        AdsModal17:"Select Tags",
        AdsModal18:"Please select 3 Tags",
        AdsModal19:"See this page for pricing information",
        AdvertisementView1:"PR",
        AdvertisementView2:"This post is promotion",
      // {t('DataDownload1:')}
        Appfooter:"",
        AttributeEach:"",
        AttributeEachforModal:"",
        AttributeEachforAdModal1:"You can select up to 3 tags",
        AttributesModal1:"Tags",
        AttributesModal2:"Done",
        AttributesModal3:"Search Tags..",
        CategoriesModal1:"Categories",
        Createcomment1:"Your comment...",
        Createcomment2:"Comment",
        Createcomment3:"Comment",
        Createpost1:"Slide 👆 to show how useful it was!",
        Createpost2:"Your opinion...",
        Createpost3:"Image has been uploaded",
        Createpost4:"Add Image",
        Createpost5:"Post",
        Createpost6:"Post",
        CropperModal1:"Save",
        CropperModal2:"Back",
        CropperModalforback:"",
        CropperModalforprof1:"",
        CropperModalforprof2:"Save",
        DataDownload1:"Click to Download",
        DataModal1:"Post Data",
        DataModal2:"Title",
        DataModal3:"Title your data...",
        DataModal4:"Discription",
        DataModal5:"Provide an overview of the data...",
        DataModal6:"Data",
        DataModal7:"Drop or select a file (jpeg, Excel, CSV, Word files can be submitted)",
        DataModal8:"I agreee to the Terms & Conditions",
        DataModal9:"File uploaded successfully!",
        DataModal10:"Back",
        DataModal11:"Submit",
        DataModal12:"Submit",
        DataModal13:"👆Convert your reports, programmes, etc. into a Book",
        DataModal14:"*Tatami can monetise any file format",
        DataModal15:"Upload has failed",
        DataModal16:"File uploaded successfully",
        DataModal17:"Free",
        DataModal18:"Not Bad",
        DataModal19:"Good",
        DataModal20:"Amazing",
        DataModal21:"Great",
        DataModal22:"Premium",
        DataModal23:"価格を選択",
        DataModal24:"¥0",
        DataModal25:"¥800",
        DataModal26:"¥3,000",
        DataModal27:"¥9,800",
        DataModal28:"¥40,000",
        DataModal29:"¥250,000",
        FollowEach1:"Follow",
        FollowEach2:"Following",
        getCroppedImg:"",
        Header1:"Are you sure to logout？",
        Header2:"Logout",
        Header3:"Back",
        Header4:"Tatami",
        Header5:"Login",
        Header6:"Post Data",
        Header7:"Go Find!",
        Header8:"Books",
        Header9:"Users",
        Header10:"Timeline",
        Header11:"Others",
        Header12:"Your Books",
        Header13:"Setting",
        Header14:"Please login first",
        Header15:"Advertise on Tatami",
        Header16:"Notifications",
        Header17:"No Notification",
        Header18:"特定商取引法に基づく表記",
           
        HomePicture1:"Posted Images",
        // HowToModal1:"Welcome to Tatami!",
        // HowToModal2:"Tatami is a new knowledge aggregation platform where you can enjoy Books made from data from all over the world!",
        // HowToModal3:"Tatami is a magic library!",
        // HowToModal4:"You can exchange opinions with other users over the Book, just as you would in a library discussion. The opinions posted are automatically aggregated.",
        // HowToModal5:"（Please login first to join the discussion！）.",
        // HowToModal6:"Take advantage of our Books!",
        // HowToModal7:"Upload any data you have by clicking the Submit Data button. Tatami will use the data collected from all over the world to create your Book!",
        // HowToModal8:"Let's start Tatami!",
        // HowToModal9:"Sign up",
        // HowToModal10:"Sign up with Google Account",
        // HowToModal11:"Register by E-mail",
        // HowToModal12:"Try Tatami Anyway",
        // HowToModal13:"Let's take a look at Books",
        HowToModal1:"Welcome to Tatami!",
        HowToModal2:"Tatami starts a thread called Book, learning about data from around the world",
        HowToModal6:"Put your data in the Tatami AI",
        // HowToModal7:"「データを投稿」ボタンから、手持ちのデータをなんでもアップロードしましょう。世界中から集めたデータと合わせ、TatamiがBook作りに活用してくれます。データがBook作りに使われると、投稿者にキャッシュバックが入ります。",
        // HowToModal5:"データがBook作りに使われると、投稿者にキャッシュバックが入ります",
        HowToModal7:"Upload any data you have by clicking the Submit Data button. Tatami will use the data collected from all over the world to create your Book!",
        HowToModal5:"",
        HowToModal3:"Let's talk peacefully on the tatami ",
        HowToModal4:"You can exchange opinions with other users over the Book. (Please login first to post!)",
        HowToModal5:"Opinions posted are automatically aggregated by the Tatami AI, so you can talk leisurely without worrying about flaming.",
        HowToModal8:"Let's start Tatami!",
        HowToModal9:"Sign up",
        HowToModal10:"Sign up with Google Account",
        HowToModal11:"Register by E-mail",
        HowToModal12:"Try Tatami Anyway",
        HowToModal13:"Let's take a look at Books",
        HowToModal14:"Once logged in, you can comment and monetize your data",
        HowToModal15:"FREE",
        HowToModal1:"Welcome to Tatami!",

        Leftnav:"",
        Load:"",
        LoginComponent1:"Email or password is not correct!",
        LoginComponent2:"Login Failed:",
        LoginComponent3:"Your Gmail account was not verified.",
        LoginComponent4:"Login Successed!",
        LoginComponent5:"Failed to Login",
        LoginComponent6:"Your account has created!",
        LoginComponent7:"Registration failed",
        LoginComponent8:"Please agree to the Terms & Conditions",
        LoginComponent9:"Tatami",
        LoginComponent10:"Login to ",
        LoginComponent11:"see this module",
        LoginComponent12:"Create new",
        LoginComponent13:"account",
        LoginComponent14:"Login or create new account",
        LoginComponent15:"Tatami",
        LoginComponent16:"Login",
        LoginComponent17:"Create new",
        LoginComponent18:"Account",
        LoginComponent19:"Your Email Address",
        LoginComponent20:"Password",
        LoginComponent21:"Login",
        LoginComponent22:"Your Email Address",
        LoginComponent23:"Password",
        LoginComponent24:"Please reenter password",
        LoginComponent25:"Agree the Terms & Conditions",
        LoginComponent26:"Create Account",
        LoginComponent27:"Sign in with Google",
        LoginComponent28:"Create new Account?",
        LoginComponent29:"Already have account?",
        MonoPicture1:"Posted Image",
        // {t('Profiledetail1')}
        NoFollowYet1:"Seemts to be no followings yet",
        NoFollowYet2:"Tips: Click here to find new user",
        NoOwingPageYet1:"No owning Books yet",
        NoOwingPageYet2:"Tip: You can own the Book to get more information",
        NoPostYet1:"No posts yet",
        NoPostYet2:"Tips: Click airplane button and show your sympathy! ",
        NoPostYetforSpecificUser1:"No post yet",
        NoPostYetforUser1:"No post yet",
        NoPostYetforUser2:"Tips: Click and find what Tatami has!",
        OriginDataEach:"",
        Pagetitle:"",
        PictureEach:"",
        Popupchat:"",
        PostedDataEach:"",
        ProfBack:"",
        Profiledetail1:" Follow",
        Profiledetail2:" Follower",
        
        ProfileRecommender1:"Let's create ",
        ProfileRecommender2:"Your Profile!",
        ProfileRecommender3:"Create Profile",
        ProgressBar:"",
        RecommenduserEach1:"User Books",
        RecommenduserEach2:"Follow",
        RecommenduserEach3:"Following",
        RepostedPostModal:"",
        RequestEach:"",
        Requestleft1:"New Follower",
        Requestleft2:"See All",
        RequestleftEach1:"Joined Tatami",
        SpecificUserPageAds1:"Active Advertisements",
        SpecificUserPageAds2:"Advertise in Tatami",
        SpecificUserPageAdsEach:"",
        SpecificUserPageDatas1:"Posted Data",
        SpecificUserPageDatas2:"See All",
        SpecificUserPageDatasEach:"",
        SpecificUserpageEach1:"Link for this user has copied!",
        SpecificUserpageEach2:"Gest User",
        SpecificUserpageEach3:"XXXX-XX-XX",
        SpecificUserpageEach4:"Share",
        SpecificUserpageEach5:"Follow",
        SpecificUserpageEach6:"Following",
        SpecificUserpageEach7:"Posts",
        SpecificUserpageEach8:"Books",
        SpecificUserpagePicture1:"Images",
        SpecificUserProfileDetail1:"About",
        UserpagePicture1:"Images",
        UsersEach:"",
        Account:"",
        AdApplication1:"Please Login",
        AdApplication2:"Advertise on Tatami",
        AdApplication3:"With Tatami Advertising, create ad strategy unlike any other!",
        AdApplication4:"If you are hesitant to advertise online due to the high unit cost, or if you are frustrated with your existing SNS ads because you can't seem to reach your target audience, Tatami Ads can provide an inexpensive and strategic solution to these issues. Tatami Advertising is the solution that you can rely on to solve your advertising problems.",
        AdApplication5:"Tatami advertisements have the potential to attract users who were previously difficult to reach",
        AdApplication6:"While submitting your ads on Tatami, you get to choose the tags under which you want your ads to appear. Tatami offers a wide range of specific tags, each associated with numerous Books. By identifying the tags that are likely to be used by the audience you wish to target, you can accurately and strategically advertise to niche user segments that were previously out of your reach. This enables you to target the user segments that matter to you the most.",
        AdApplication7:"Tatami's advertising is focused on producing measurable results.",
        AdApplication8:"Tatami provides two options for advertising styles: impression-based and click-based. In the impression-based style, the maximum number of impressions is utilized every time an ad is displayed. Whereas, in the click-based style, the maximum number of impressions is not exhausted until the ad is clicked. The maximum number of impressions that can be selected are 500, 1,000, and 3,000. It is essential to choose the appropriate method according to the advertising style that your company is aiming for.",
        AdApplication9:"A comfortable advertising style",
        AdApplication10:"Tatami ads are displayed in a non-intrusive manner on Books and timelines, allowing for natural recognition of targeted ads by users.",
        AdApplication11:"The cost performance is excellent and highly effective.",
        AdApplication12:"If you compare the cost per impression of ads and the amount spent per click, Tatami Advertising is a more efficient option than other Internet and SNS ads.Please consider Tatami Advertising.",
        AdApplication13:"Create your ad",
        AdApplication14:"Check your ads",
        AdApplication15:"",
        NotationsSCTA1:"ログインしてください。",
        NotationsSCTA2:"特定商取引法に基づく表記",
        NotationsSCTA3:"特定商取引法に基づく表記",
        NotationsSCTA4:"",
        NotationsSCTA5:"販売業者の名称",
        NotationsSCTA6:"",
        NotationsSCTA7:"所在地",
        NotationsSCTA8:"請求があったら遅滞なく開示します。",
        NotationsSCTA9:"電話番号",
        NotationsSCTA10:"請求があったら遅滞なく開示します。",
        NotationsSCTA11:"メールアドレス",
        NotationsSCTA12:"tatamiincs1@gmail.com",
        NotationsSCTA13:"新規広告を出稿",
        NotationsSCTA14:"出稿済み広告を確認",
        NotationsSCTA16:"運営統括責任者",
        NotationsSCTA17:"",
        NotationsSCTA18:"追加手数料等の追加料金",
        NotationsSCTA19:"なし",
        NotationsSCTA20:"交換および返品（返金ポリシー）",
        NotationsSCTA21:"＜お客様都合の返品・交換の場合＞ ",
        NotationsSCTA30:"購入処理完了前の商品：ブラウザにて購入前ページに遷移することでキャンセル可能です。 購入処理完了後の商品：Book系商品の場合、内容未確認の商品かつ誤購入商品に限り、サポートセンター（メールアドレス：tatamiincs1@gmail.com）に連絡いただくことでキャンセル可能です。 ",
        NotationsSCTA33:"広告系商品の返品・キャンセルは誤購入商品に限り、サポートセンター（メールアドレス：tatamiincs1@gmail.com）に連絡いただくことでキャンセル可能です。内容確認後の商品は、返品・交換はお受けしておりません。 ",
        NotationsSCTA31:"＜商品に不備がある場合＞ ",
        NotationsSCTA32:"返金又は新しい商品と交換いたします。まずはお客様サポートセンター（メールアドレス：tatamiincs1@gmail.com）までご連絡ください。",
        NotationsSCTA22:"引渡時期",
        NotationsSCTA23:"注文後すぐにご利用いただけます。",
        NotationsSCTA24:"受け付け可能な決済手段",
        NotationsSCTA25:"クレジットカードまたは国内の銀行振込",
        NotationsSCTA26:"決済期間",
        NotationsSCTA27:"クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 3 日以内にお振り込みいただく必要があります。",
        NotationsSCTA28:"販売価格",
        NotationsSCTA29:"各商品ページに記載の金額",
        Analytics:"",
        BankAccount1:"Please Login",
        BankAccount2:"Register Bank Account",
        BankAccount3:"To effectively monetize Tatami, please register your bank account.",
        BankAccount4:"Why not monetize your data once it's posted on Tatami? There's no review process required for monetization, just adoption by Tatami AI.",
        BankAccount5:"If you post data, please register your account first!",
        BankAccount6:"Once you have registered your account, you will receive cash back to you when your data is used. The only preparation required is account registration.",
        BankAccount7:"You can easily build a page without any design or custom coding.",
        BankAccount8:"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS",
        BankAccount9:"I have read and agree to the Privacy Policy and Terms & Conditions*",
        BankAccount10:"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS",
        BankAccount11:"You can easily build a page without any design or custom coding.",
        BankAccount12:"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS",
        BankAccount13:"Register new account",
        BankAccount14:"Check your account",
        BankAccount15:"Check your account",
        BankAccount16:"※It might take up to 5 seconds for the registration screen to load",
        ChangeLanguage1:"Language Setting",
        ChangeLanguage2:"Japanese【★】",
        ChangeLanguage3:"Japanese",
        ChangeLanguage4:"English【★】",
        ChangeLanguage5:"English",
        ChangeLanguage6:"Back",
        Commentview:"",
        EmailVerify1:"Tatami",
        EmailVerify2:"Authorizing",
        EmailVerify3:"Email",
        EmailVerify4:"Congrats!",
        EmailVerify5:"Your Email adress has been confirmed",
        EmailVerify6:"Redirecting...",
        // {t('FollowingPageList1')}
        Followed1:"Follow",
        Followed2:"Follower",
        Following1:"Follow",
        Following2:"Follower",
        FollowingPage:"",
  
        FollowingPageList1:"Posts",
        FollowingPageList2:"Following Books",
        FollowingPageList3:"Likes",
        FollowingPageList4:"Datas",
        FollowingPageList4:"Please login to post!",
  
        FollowingPageListEach:"",
        ForgotPassword1:"Invalid Email!",
        ForgotPassword2:"Error! Plesase check your network status!",
        ForgotPassword3:"Error! Please check your network status!",
        ForgotPassword4:"Invalid Email!",
        ForgotPassword5:"Reset your password",
        ForgotPassword6:"Your new password has been sent",
        ForgotPassword7:"Enter the authenticated Email address and a password reset link will be sent to you.",
        ForgotPassword8:"Email has been sent to",
        ForgotPassword9:". If this email address is registered with Tatami, you will receive instructions on how to set a new password.",
        ForgotPassword10:"Send a password reset Email",
        // {t('HowTo')}
        FriendRecommendslider1:"User Books",
        FriendRecommendslider2:"Follow",
        FriendRecommendslider3:"Following",
        GetPage1:"You can get information",
        GetPage2:"on this Book",
        GetPage3:"Purchase this Book",
        GotPage1:"Thank you",
        GotPage2:"for purchasing!",
        Home1:"Recently Followed Books",
        Home2:"See All",
        Home3:"Please login to post",
        Home4:"Tatami AI Activities",
        Home5:"Loading...",
        Home6:"Organizing Books",
        Home7:"Next Tag integration in",
        Home8:"Recent Posts by Following Users",
        HomeReccomendPage:"",
        HowTo1:"How to use Tatami?",
        HowTo2:"Welcome to Tatami!",
        HowTo3:"Tatami is the world's first data flea market. When you list your data, Tatami AI organizes the content and transforms it into a product called a 'Book' that's easy for anyone to understand. By purchasing a Book, you can download the original data. If someone buys your listed data, you'll receive an incentive.",
        HowTo4:"Let’s Get Started by Listing Your Data!",
        HowTo5:"You can list any data stored on your smartphone. Whether it's an image you found on Twitter, spam emails received this morning, a report you wrote during your university days, or a compilation of your hobbies, simply click the button at the bottom right of your screen to list it with one click! The submitted data will be neatly compiled into a 'Book' for easy viewing. (Listing is available for free)",
        HowTo6:"List Your Data Now",
        HowTo7:"Find Data That Interests You",
        HowTo8:"Use the 'Find Books' feature to browse data listed on Tatami. When you find a Book that catches your eye, share your thoughts by posting a comment. Don’t forget to rate how helpful the Book is. By following a Book, you can stay updated with its latest updates on your timeline.",
        HowTo9:"Browse Data",
        HowTo10:"Get Your Data!",
        HowTo11:"By purchasing a Book, you can access and download the complete original data. Additionally, you'll gain access to various information related to the Book.",
        HowTo12:"Find Data That Interests You",
        HowTo13:"Connect with Other Users",
        HowTo14:"You can follow users you like. Track the activities of the users you follow on your timeline, and receive notifications when they post new data.",
        HowTo15:"Register a revenue account with Tatami",
        HowTo16:"Find Users",
        HowTo17:"Tatami's platform attracts a diverse global audience. Choose between impression-based or click-based advertising to optimize your reach.",
        HowTo18:"Advertise on Tatami",
        HowTo19:"Please Login",
        LikedPosts1:"Gest User",
        LikedPosts2:"XXXX-XX-XX",
        LikedPosts3:"Posts",
        LikedPosts4:"Books",
        LikedPosts5:"Likes",
        LikedPosts6:"Data",
        LikedPosts7:"Please login to post",
    
        Login1:"'Your Gmail account was not verified.",
        Login2:"LoginFailed",
        Login3:"RegisterFailed",
        Login4:"Tatami",
        Login5:"Login to ",
        Login6:"Tatami",
        Login7:"Create Tatami ",
        Login8:"Account",
        Login9:"Email",
        Login10:"Password",
        Login11:"自動でログインする",
        Login12:"Forgot Password?",
        Login13:"Login",
        Login14:"Email",
        Login15:"Password",
        Login16:"Confirm your Password",
        Login17:"I agreee to the Terms & Conditions",
        Login18:"Create Account",
        Login19:"Sign in with Google",
        Login20:"Click here if you are a new user of Tatami.",
        Login21:"Have an account?",
        Login22:"Start with Google Account",
        Login23:"Register by Email",
        Logout1:"Logout",
        Logout2:"Logout",
        Logout3:"Return",
        DeleteAccount1:"Delete Account",
        DeleteAccount2:"Delete Account",
        DeleteAccount3:"Back",
  
        Mono1:"Followed this Book",
        Mono2:"Copied link to this Book",
        Mono3:"Would you like to retrieve information on this Book?",
        Mono4:"Owning Book",
        Mono5:"Follow Books",
        Mono6:"Following this Book",
        Mono7:"Share",
        Mono8:"Useful!",
        Mono9:"0%",
        Mono10:"Posts",
        Mono11:"Overall",
        Mono12:"Discussion Points",
        Mono13:"Purchase Book to see all",
        Mono14:"Datas you can",
        Mono15:"get from this Book",
        Mono16:"See All",
        Mono17:"Tatami AI",
        Mono18:"created",
        Mono19:"Next update in",
        Mono20:"Please login to post",
        Mono21:"Book Report by Tatami",
        Mono22:"Opinions of Tatami users",
        Mono23:"Posts",
        Mono24:"Information that can be obtained with purchase (in part)",
        Mono25:"Resent Posts on this Book",
        Mono26:"※Until the first update, Tatami's summary of public opinion is shown.",
        Mono27:"Post your opinion from here!",
        Mono28:"Book will be improved with it.",
        Mono29:"Related",
        Mono30:"Data Origin User",
        Mono31:"This page is already followed",
        OwningEach1:"Total useful!",
        OwningPage1:"Discussion Points",
        OwningPage2:"Key Points to Understand this Book",
        OwningPage3:"Post",
        OwningPage4:"This Month Impression",
        OwningPage5:"Female Access",
        OwningPage6:"Male Access",
        OwningPage7:"Tatami's View",
        OwningPages1:"Your Books",
        OwningPages2:"Title..",
        OwningPages3:"Your Bookshelf",
        PostedData1:"Posts",
        PostedData2:"Books",
        PostedData3:"Liked",
        PostedData4:"Data",
        Postview1:"Are you sure to delete this post?",
        Postview2:"Delete",
        Postview3:"Back",
        Postview4:"Useful!: ",
        Postview5:"Close Comments",
        Postview6:"Useful!: ",
        Postview7:"Close Comments",
        Postview8:"Book has been deleted",
        Postview9:"Added a New Book to the Bookshelf",
        PostviewforPage1:"Are you sure to delete this post?",
        PostviewforPage2:"Delete",
        PostviewforPage3:"Back",
        PostviewforPage4:"Useful!: ",
        PostviewforPage5:"Close Comments",
        PostviewforPage6:"Useful!: ",
        PostviewforPage7:"Close Comments",
        PostviewforPage8:"Book has been deleted",
        ReccomendPosts:"",
        Recommend1:"Discover the best way to utilize Tatami",
        Recommend2:"Categories",
        Recommend3:"See All",
        Recommend4:"Trending Tags",
        Recommend5:"See All",
        Recommend6:"Tatami AI",
        Recommend7:"Generating Booksfrom posted data...",
        Recommend8:"Next generation in",
        Recommend9:"Find Books",
        Recommend10:"Tatami AI",
        Recommend11:"integrates",
        Recommend12:"Tags",
        Recommend13:"Your interest..",
        Recommend14:"Find Books",
        Recommend15:"Latest Books for your business",
        Recommend16:"Books that may be useful for selected ",
        Recommend17:"Books that may be useful for selected ",
        Recommend18:"Books that may be useful for selected requirements",
        Recommend19:"Post data from here!",
        Recommend20:"Tatami AI turns it into a Book",
        RecommendEach1:"Useful!  ",
        Recommenduser1:"Found Users",
        Recommenduser2:"New Users",
        Recommenduser3:"User Name..",
        Recommenduser4:"Find Users",
        Request:"",
        ResetPassword1:"Error! Plesase check your network status!",
        ResetPassword2:"Password doesn't match",
        ResetPassword3:"Error! Plesase check your network status!",
        ResetPassword4:"Error! Plesase check your network status!",
        ResetPassword5:"Enter your new password",
        ResetPassword6:"Reset your password",
        ResetPassword7:"Password has been reseted",
        ResetPassword8:"Authorizing Email",
        ResetPassword9:"Enter your new password",
        ResetPassword10:"Enter new password",
        ResetPassword11:"Confirm new password",
        ResetPassword12:"Send",
        ResetPassword13:"Password has been reseted",
        ResetPassword14:"Login",

        NotificationEach1:"Commented on your post.",
        NotificationEach2:"Liked your post.",
        NotificationEach3:"Reposted your post.",
        NotificationEach4:"Replied to your comment.",
        NotificationEach5:"New posts on the following Book.",
        NotificationEach6:"New Book generated from your data.",
        NotificationEach7:"Your Book got purchased.",
        NotificationEach8:"Your Book got followed.",
        NotificationEach9:"Followed you.",
        NotificationEach10:"New Book generated by your following user",
        NotificationEach11:"Your Book got commented",
        NotificationSetting1:"Email Notification Settings",
        NotificationSetting2:"Activities",
        NotificationSetting3:"Notify when your post liked",
        NotificationSetting4:"Notify when your post commented",
        NotificationSetting5:"Notify when your post reposted",
        NotificationSetting13:"Notify when you get followed",
        NotificationSetting6:"Book",
        NotificationSetting7:"Notify when new Book generated from your data",
        NotificationSetting8:"Notify when your Book get followed",
        NotificationSetting9:"Notify when your Book get owned",
        NotificationSetting10:"Notify when your following Book get commented",
        NotificationSetting11:"Notify when new Book has been generated from your following user's data",
        NotificationSetting12:"特定商取引法に基づく表示",
        NotificationSetting14:"通知設定",
        NotificationSetting15:"Notify when your Book get commented",
  
  // {t('Recommend1')}
        Settings1:"Setting",
        Settings2:"General",
        Settings3:"Profile",
        Settings4:"How to use Tatami?",
        Settings5:"Language Setting",
        Settings6:"Monetize",
        Settings7:"Advertise on Tatami",
        Settings8:"Bank Account",
        Settings9:"Other",
        Settings10:"Logout",
        Settings11:"Delete Account",
        Settings12:"Notions for SCTA",
        Settings13:"Notification",
        SpecificUserpage:"",
        Success1:"Your Bank Account",
        Success2:"has been registered",
        Userpage1:"Gest User",
        Userpage2:"Posts",
        Userpage3:"Following Books",
        Userpage4:"Liked",
        Userpage5:"Data",
        Userpage6:"",
        Userpage7:"Book",
        Users:"",
        Verify1:"Please check your mailbox to continue",
        Verify2:"Email has been sent to you. Please check your email and click on the link provided.",
        Verify:"",  
      // 他の英語の翻訳もここに

      DataDownload1:"Download is complete!",
      DataDownload2:"An error occurred while downloading",
      DataDownload3:"Click to download",


      
    }
  },
  ja: {
    translation: {
      AccountEach1:"アカウント情報",
      AccountEach2:"トップ画像を追加",
      AccountEach3:"トップ画像がアップロードされました。",
      AccountEach4:"アカウント名",
      AccountEach5:"生年月日",
      AccountEach6:"性別",
      AccountEach7:"自己紹介",
      AccountEach8:"編集結果を保存",
      AccountEach9:"新規作成",
      AccountEach10:"アカウントを削除",
      AccountEach11:"必須項目を記入してください",
      AccountEach12:"年",
      AccountEach13:"月",
      AccountEach14:"日",
      AccountEach15:"男性",
      AccountEach16:"女性",
      AccountEach17:"その他",
      AccountEach18:"回答しない",
      AdsModal1:"広告を出稿",
      AdsModal2:"広告文（200文字以下）",
      AdsModal3:"URL",
      AdsModal4:"方式",
      AdsModal5:"インプレッション型",
      AdsModal6:"クリック型",
      AdsModal7:"ターゲット回数",
      AdsModal8:"画像が選択されていません",
      AdsModal9:"規約に同意する",
      AdsModal10:"画像を追加",
      AdsModal11:"プレビューを表示",
      AdsModal12:"プレビューを表示",
      AdsModal13:"広告を出稿",
      AdsModal14:"広告を出稿",
      AdsModal15:"広告を出稿",
      AdsModal16:"優先表示Tag【Basic/Premiumプランのみ】",
      AdsModal17:"Tagを選択",
      AdsModal18:"Tagを3つ選択してください",
      AdsModal19:"※価格詳細はこちらをご覧ください",
      AdvertisementView1:"PR",
      AdvertisementView2:"この投稿はプロモーションです",
    // {t('DataDownload1:')}
      Appfooter:"",
      AttributeEach:"",
      AttributeEachforModal:"",
      AttributeEachforAdModal1:"Tagは3つまで選択可能です",
      AttributesModal1:"タグ",
      AttributesModal2:"完了",
      AttributesModal3:"Tagを検索",
      CategoriesModal1:"カテゴリー",
      Createcomment1:"コメントを入力...",
      Createcomment2:"コメント",
      Createcomment3:"コメント",
      Createpost1:"👆スライドしてBookの役立ち度を示そう！",
      Createpost2:"意見をポスト...",
      Createpost3:"画像がアップロードされました。",
      Createpost4:"画像を追加",
      Createpost5:"投稿",
      Createpost6:"投稿",
      CropperModal1:"はい",
      CropperModal2:"閉じる",
      CropperModalforback:"",
      CropperModalforprof1:"はい",
      CropperModalforprof2:"閉じる",
      DataDownload1:"クリックでダウンロード",
      DataModal1:"データを投稿",
      DataModal2:"タイトル",
      DataModal3:"データのタイトルを入力...",
      DataModal4:"データの概要",
      DataModal5:"中身を大まかに入力...",
      DataModal6:"データ",
      DataModal7:"jpeg,Excel,CSV,Wordファイルが投稿可能です",
      DataModal8:"規約に同意する",
      DataModal9:"File uploaded successfully!",
      DataModal10:"閉じる",
      DataModal11:"投稿",
      DataModal12:"投稿",
      DataModal13:"👇️お手持ちのデータを本棚に追加しましょう！",
      DataModal14:"※Tatamiではあらゆるファイル形式を収益化できます",
      DataModal15:"アップロードに失敗しました",
      DataModal16:"データが安全に投稿されました",
      DataModal17:"無料",
      DataModal18:"まあまあ",
      DataModal19:"そこそこ",
      DataModal20:"なかなか",
      DataModal21:"Great",
      DataModal22:"Premium",
      DataModal23:"価格を選択",
      DataModal24:"¥0",
      DataModal25:"¥800",
      DataModal26:"¥3,000",
      DataModal27:"¥9,800",
      DataModal28:"¥40,000",
      DataModal29:"¥250,000",
      DataModal30:"自作のデータ",
      DataModal31:"見つけたデータ",
      FollowEach1:"フォロー",
      FollowEach2:"フォロー中",
      getCroppedImg:"",
      Header1:"ログアウトしますか？",
      Header2:"はい",
      Header3:"キャンセル",
      Header4:"Tatami",
      Header5:"ログイン",
      Header6:"データを投稿",
      Header7:"見つけよう",
      Header8:"タイムライン",
      Header9:"ユーザーを探す",
      Header10:"新着Book",
      Header11:"その他",
      Header12:"あなたの本棚",
      Header13:"設定",
      Header14:"ログインしてください",
      Header15:"広告を出す",
      Header16:"通知",
      Header17:"通知はありません",
      Header18:"特定商取引法に基づく表記",
      Header19:"Tatamiとは？",
      NotificationEach1:"あなたの投稿にコメントしました。",
      NotificationEach2:"あなたの投稿にいいねしました。",
      NotificationEach3:"あなたの投稿をリポストしました。",
      NotificationEach4:"あなたのコメントに返信しました。",
      NotificationEach5:"フォローしているBookに新しい投稿があります。",
      NotificationEach6:"あなたのデータから新しいBookが生成されました。",
      NotificationEach7:"あなたのBookが本棚に追加されました。",
      NotificationEach8:"あなたのBookがフォローされました。",
      NotificationEach9:"あなたをフォローしました。",
      NotificationEach10:"フォローしているユーザーからBookが生まれました",
      NotificationEach11:"あなたのBookにポストがあります",
         
      HomePicture1:"最近の画像",
      // HowToModal1:"Tatamiへようこそ",
      // HowToModal2:"Tatamiでは、世界中のデータから作られたBookを楽しむ、新しい知識集約プラットフォームです!",
      // HowToModal6:"Bookはあなたのデータから作られます",
      // // HowToModal7:"「データを投稿」ボタンから、手持ちのデータをなんでもアップロードしましょう。世界中から集めたデータと合わせ、TatamiがBook作りに活用してくれます。データがBook作りに使われると、投稿者にキャッシュバックが入ります。",
      // // HowToModal5:"データがBook作りに使われると、投稿者にキャッシュバックが入ります",
      // HowToModal7:"「データを投稿」ボタンから、手持ちのデータをなんでもアップロードしましょう。世界中から集めたデータと合わせ、TatamiがBook作りに活用してくれます。",
      // HowToModal5:"",
      // HowToModal3:"Tatamiは、魔法の図書館です",
      // HowToModal4:"図書館でディスカッションするように、Bookを巡って他の利用者と意見を交わすことができます。（投稿にはログインが必要です！）投稿された意見は自動で集約されます。",
      // HowToModal5:"また、気になるBookがあれば保存も可能です。Bookに対する深い洞察、生成に使ったデータなど色々な特典が得られます。",
      // HowToModal8:"さあ、Tatamiを始めましょう",
      // HowToModal9:"サインアップ",
      // HowToModal10:"Google Accountで登録",
      // HowToModal11:"Emailで登録",
      // HowToModal12:"とりあえず使ってみる",
      // HowToModal13:"Bookを見てみる",
      HowToModal2:"Tatamiは、世界中のデータを学習してBookというスレッドを立てます",
      HowToModal6:"面白いデータで面白いBookを",
      // HowToModal7:"「データを投稿」ボタンから、手持ちのデータをなんでもアップロードしましょう。世界中から集めたデータと合わせ、TatamiがBook作りに活用してくれます。データがBook作りに使われると、投稿者にキャッシュバックが入ります。",
      // HowToModal5:"データがBook作りに使われると、投稿者にキャッシュバックが入ります",
      HowToModal7:"Tatamiではデータを投稿するとBook（スレッド）を立てられます。「データを投稿」ボタンから、手持ちのデータをなんでもアップロードしましょう。Tatami AIが学習し、より面白く示唆に富んだBookを作るようになります",
      HowToModal5:"",
      HowToModal3:"畳の上で、ゆっくり話そう",
      HowToModal4:"Bookを巡って他の利用者と意見を交わすことができます。（投稿にはログインが必要です！）",
      HowToModal5:"投稿された意見はTatami AIにより自動的に集約されるので炎上を気にせずのんびり話せます。Tatami AIもみなさんの意見で学習し、Bookそのものを修正します。",
      HowToModal8:"さあ、Tatamiを始めましょう",
      HowToModal9:"サインアップ",
      HowToModal10:"Google Accountでログイン",
      HowToModal11:"Emailで登録",
      HowToModal12:"とりあえず使ってみる",
      HowToModal13:"Bookを見てみる",
      HowToModal14:"ログインするとデータを出品できるようになります",
      HowToModal15:"無料",
      Leftnav:"",
      Load:"",
      LoginComponent1:"Email or password is not correct!",
      LoginComponent2:"Login Failed:",
      LoginComponent3:"Your Gmail account was not verified.",
      LoginComponent4:"ログインに成功しました。",
      LoginComponent5:"ログインに失敗しました。",
      LoginComponent6:"登録に成功しました。",
      LoginComponent7:"登録に失敗しました。",
      LoginComponent8:"規約に同意する必要があります",
      LoginComponent9:"Tatami",
      LoginComponent10:"ログインすると ",
      LoginComponent11:"この機能を使えます",
      LoginComponent12:"アカウントを",
      LoginComponent13:"新規作成",
      LoginComponent14:"ログインまたはアカウントを新規作成",
      LoginComponent15:"Tatami",
      LoginComponent16:"Login",
      LoginComponent17:"Create",
      LoginComponent18:"Account",
      LoginComponent19:"Your Email Address",
      LoginComponent20:"Password",
      LoginComponent21:"ログイン",
      LoginComponent22:"メールアドレス",
      LoginComponent23:"パスワード",
      LoginComponent24:"パスワード（確認）",
      LoginComponent25:"規約に同意する",
      LoginComponent26:"アカウントを作成",
      LoginComponent27:"Sign in with Google",
      LoginComponent28:"初めてTatamiをご利用される方はこちら",
      LoginComponent29:"アカウントをお持ちの方はこちら",
      MonoPicture1:"最近の画像",
      // {t('Profiledetail1')}
      NoFollowYet1:"フォローしているユーザーがいないようです",
      NoFollowYet2:"ヒント：クリックしてユーザーを探してみましょう！",
      NoOwingPageYet1:"所有しているBookはありません",
      NoOwingPageYet2:"ヒント：気になるBookは元データごと保存できます！",
      NoPostYet1:"投稿がまだないようです",
      NoPostYet2:"ヒント：投稿ボタンから最初の意見を投稿をしよう！",
      NoPostYetforSpecificUser1:"投稿はまだありません",
      NoPostYetforUser1:"まだ投稿がないようです",
      NoPostYetforUser2:"ヒント：クリックして気になるBookを探してみましょう！",
      OriginDataEach:"",
      Pagetitle:"",
      PictureEach:"",
      Popupchat:"",
      PostedDataEach:"",
      ProfBack:"",
      Profiledetail1:"人のフォロー",
      Profiledetail2:"人のフォロワー",
      
      ProfileRecommender1:"まずはプロフィールを",
      ProfileRecommender2:"登録しよう",
      ProfileRecommender3:"プロフィールを作成",
      ProgressBar:"",
      RecommenduserEach1:"ユーザーページ",
      RecommenduserEach2:"フォロー",
      RecommenduserEach3:"フォロー中",
      RepostedPostModal:"",
      RequestEach:"",
      Requestleft1:"新しいフォロワー",
      Requestleft2:"全て表示",
      RequestleftEach1:"にTatamiに参加",
      SpecificUserPageAds1:"掲載中の広告",
      SpecificUserPageAds2:"Tatamiに広告を出す",
      SpecificUserPageAdsEach:"",
      SpecificUserPageDatas1:"最近投稿したデータ",
      SpecificUserPageDatas2:"全て見る",
      SpecificUserPageDatasEach:"",
      SpecificUserpageEach1:"リンクをコピーしました。",
      SpecificUserpageEach2:"ゲストユーザー",
      SpecificUserpageEach3:"XXXX-XX-XX",
      SpecificUserpageEach4:"シェア",
      SpecificUserpageEach5:"フォロー",
      SpecificUserpageEach6:"フォロー中",
      SpecificUserpageEach7:"投稿",
      SpecificUserpageEach8:"Book",
      SpecificUserpagePicture1:"最近の画像",
      SpecificUserProfileDetail1:"About",
      UserpagePicture1:"最近の画像",
      UsersEach:"",
      Account:"",
      AdApplication1:"ログインしてください。",
      AdApplication2:"広告出稿",
      AdApplication3:"Tatamiの広告なら、これまでにない広告戦略が可能です",
      AdApplication4:"ネット広告は単価が高くて躊躇していませんか。既存のSNS広告で思ったユーザー層にアクセスできず、不満を感じていらっしゃいませんか。Tatami広告ならその課題を非常に安価かつ戦略的に解決できます。本当に「意味のある」広告を。ぜひTatami広告をご検討ください。",
      AdApplication5:"これまでアクセスできなかったユーザーに訴求できます",
      AdApplication6:"Tatamiでは、広告出稿時にどのTagに広告を出稿するか選択できます。選択したTagがついたBookには、貴社の広告が優先的に表示されます。TatamiにはBookにあったTagが数多く存在し、それぞれのTagは無数のBookに紐づいています。貴社のリーチしたいユーザーが多く存在するTagを見極めて出稿すれば、これまで届かなかったニッチなユーザー層、本当にアクセスしたいユーザー層に戦略的かつ的確に広告出稿が可能です。",
      AdApplication7:"Tatamiの広告は、成果主義です",
      AdApplication8:"Tatamiでは、広告出稿スタイルを「インプレッション型」と「クリック型」から選択可能です。インプレッション型は広告が表示されるたびに上限回数が消費され、クリック型はクリックされるまで上限回数が消費されません。上限回数は500回、1000回、3000回から選択可能です。貴社の目指す広告スタイルに合わせ、最適な方式をご選択ください。",
      AdApplication9:"違和感のない広告スタイル",
      AdApplication10:"Tatamiの広告は、ユーザーに不快感を与えない形でBookやタイムライン上に表示されます。狙ったボリュームに自然な認知を与えることが可能です。",
      AdApplication11:"コストパフォーマンスは圧倒的です",
      AdApplication12:"インプレッションあたりの広告単価、クリックあたりの消費金額で比較すれば、他のインターネット広告やSNS広告と比較にならない手軽さです。ぜひTatami広告をご検討ください。",
      AdApplication13:"新規広告を出稿",
      AdApplication14:"出稿済み広告を確認",
      AdApplication15:"大越航",
      NotationsSCTA1:"ログインしてください。",
      NotationsSCTA2:"特定商取引法に基づく表記",
      NotationsSCTA3:"特定商取引法に基づく表記",
      NotationsSCTA4:"大越航",
      NotationsSCTA5:"販売業者の名称",
      NotationsSCTA6:"大越航",
      NotationsSCTA7:"所在地",
      NotationsSCTA8:"請求があったら遅滞なく開示します。",
      NotationsSCTA9:"電話番号",
      NotationsSCTA10:"請求があったら遅滞なく開示します。",
      NotationsSCTA11:"メールアドレス",
      NotationsSCTA12:"tatamiincs1@gmail.com",
      NotationsSCTA13:"新規広告を出稿",
      NotationsSCTA14:"出稿済み広告を確認",
      NotationsSCTA16:"運営統括責任者",
      NotationsSCTA17:"大越航",
      NotationsSCTA18:"追加手数料等の追加料金",
      NotationsSCTA19:"なし",
      NotationsSCTA20:"交換および返品（返金ポリシー）",
      NotationsSCTA21:"＜お客様都合の返品・交換の場合＞ ",
      NotationsSCTA30:"購入処理完了前の商品：ブラウザにて購入前ページに遷移することでキャンセル可能です。  ",
      NotationsSCTA33:"購入処理完了後の商品：内容未確認の商品かつ誤購入商品に限り、サポートセンター（メールアドレス：tatamiincs1@gmail.com）に連絡いただくことでキャンセル可能です。 ",
      NotationsSCTA31:"＜商品に不備がある場合＞ ",
      NotationsSCTA32:"返金又は新しい商品と交換いたします。まずはお客様サポートセンター（メールアドレス：tatamiincs1@gmail.com）までご連絡ください。",
      NotationsSCTA22:"引渡時期",
      NotationsSCTA23:"注文後すぐにご利用いただけます。",
      NotationsSCTA24:"受け付け可能な決済手段",
      NotationsSCTA25:"クレジットカードまたは国内の銀行振込",
      NotationsSCTA26:"決済期間",
      NotationsSCTA27:"クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 3 日以内にお振り込みいただく必要があります。",
      NotationsSCTA28:"販売価格",
      NotationsSCTA29:"各商品ページに記載の金額",
      Analytics:"",
      BankAccount1:"ログインしてください。",
      BankAccount2:"口座情報登録",
      BankAccount3:"銀行口座を登録してTatamiを効果的に収益化しましょう",
      BankAccount4:"Tatamiにデータを投稿したら、収益化しませんか？　他SNSのように、収益化にあたり審査は一切不要です。収益化に必要な条件は、Tatami AIがデータを採用するかだけ。",
      BankAccount5:"データを投稿するなら、ぜひ口座登録を",
      BankAccount6:"口座登録をしておけば、データが使用された時にあなたにキャッシュバックが入ります。必要な準備は口座登録だけです。",
      BankAccount7:"口座登録は、安全に",
      BankAccount8:"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS",
      BankAccount9:"I have read and agree to the Privacy Policy and Terms & Conditions*",
      BankAccount10:"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS",
      BankAccount11:"You can easily build a page without any design or custom coding.",
      BankAccount12:"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS",
      BankAccount13:"新規口座情報を登録",
      BankAccount14:"口座情報を確認",
      BankAccount15:"口座情報を確認",
      BankAccount16:"※登録画面への遷移には５秒ほどかかる場合があります",
      ChangeLanguage1:"言語設定",
      ChangeLanguage2:"日本語【★】",
      ChangeLanguage3:"日本語",
      ChangeLanguage4:"英語【★】",
      ChangeLanguage5:"英語",
      ChangeLanguage6:"戻る",

      
      
      NotificationSetting1:"メール通知設定",
      NotificationSetting2:"タイムライン",
      NotificationSetting3:"Like時に通知",
      NotificationSetting4:"Comment時に通知",
      NotificationSetting5:"Repost時に通知",
      NotificationSetting13:"新規フォロー時に通知",
      NotificationSetting6:"Book",
      NotificationSetting7:"投稿データからBookが生成された時",
      NotificationSetting8:"あなたのBookがフォローされた時",
      NotificationSetting9:"あなたのBookが購入された時",
      NotificationSetting10:"フォロー中のBookにPostがあった時",
      NotificationSetting11:"フォロー中のユーザーがBookを作成した時",
      NotificationSetting12:"特定商取引法に基づく表記",
      NotificationSetting14:"通知設定",
      NotificationSetting15:"あなたのBookにPostがあった時",
      Commentview:"",
      EmailVerify1:"Tatami",
      EmailVerify2:"検証しています",
      EmailVerify3:"Eメール",
      EmailVerify4:"おめでとうございます！",
      EmailVerify5:"メールアドレスが確認されました。",
      EmailVerify6:"リダイレクトしています...",
      // {t('FollowingPageList1')}
      Followed1:"フォロー",
      Followed2:"フォロワー",
      Following1:"フォロー",
      Following2:"フォロワー",
      FollowingPage:"",

      FollowingPageList1:"投稿",
      FollowingPageList2:"フォロー中のBook",
      FollowingPageList3:"いいね",
      FollowingPageList4:"データ",
      FollowingPageList5:"投稿するにはログインしてください",

      FollowingPageListEach:"",
      ForgotPassword1:"無効なメールです！",
      ForgotPassword2:"間違い！ ネットワーク接続を確認する。",
      ForgotPassword3:"間違い！ ネットワーク接続を確認する。",
      ForgotPassword4:"無効なメールです！",
      ForgotPassword5:"パスワードをリセットします",
      ForgotPassword6:"パスワードを送信しました",
      ForgotPassword7:"ユーザーアカウントの認証済み電子メールアドレスを入力すると、パスワードリセットリンクが送信されます。",
      ForgotPassword8:"電子メールは",
      ForgotPassword9:"に送信されました。 このメールアドレスがTatamiに登録されている場合は、新しいパスワードの設定方法についての説明が届きます。",
      ForgotPassword10:"パスワードリセットメールを送信",
      // {t('HowTo')}
      FriendRecommendslider1:"ユーザーページ",
      FriendRecommendslider2:"フォロー",
      FriendRecommendslider3:"フォロー中",
      GetPage1:"このBookの情報を",
      GetPage2:"取得できます",
      GetPage3:"Bookを本棚に追加",
      GotPage1:"ご購入いただき",
      GotPage2:"ありがとうございます",
      Home1:"最近フォローしたBook",
      Home2:"全て表示",
      Home3:"投稿するにはログインしてください",
      Home4:"Tatami AIの動き",
      Home5:"Loading...",
      Home6:"Bookを整理中...",
      Home7:"次のTag統合まで",
      Home8:"タイムライン",
      HomeReccomendPage:"",
      HowTo1:"Tatamiの使い方",
      HowTo2:"「今日見たデータ」を見つけよう",
      HowTo3:"Tatamiへようこそ！Tatamiはあなたが日々見たり作ったりしたデータを記録、シェアするサービスです。Tatamiを使えば、あなただけのデータの本棚を作って公開できます！",
      HowTo4:"本棚を作ろう！",
      HowTo5:"今日見た見かけたあらゆるデータを投稿しましょう。今日読んだ論文、ブログ、Xで見かけたまとめ画像、今朝届いた迷惑メール、大学時代に書いたレポートなどなど、画面右下のボタンからワンクリックで記録します。投稿されたデータは分かりやすく「Book」という形でまとめられ、あなたの本棚に追加されます",
      HowTo6:"データを投稿してみる",
      HowTo7:"本棚を充実させよう！",
      HowTo8:"Tatamiには、Tatamiユーザーが見かけたデータが日々Bookとして投稿されます。気になるBookは積読しておきましょう。本棚に追加すれば、元データのダウンロードも可能になります",
      HowTo9:"Bookを探してみる",
      HowTo10:"ユーザーと繋がって充実を加速しましょう！",
      HowTo11:"Tatamiには、いろいろな分野に興味があるユーザーが集まっています。興味範囲が似通ったユーザーとつながってリアルタイムで投稿される経済、ガジェット、スポーツから先端科学技術まで、あらゆるデータをリアルタイムにフォローしましょう",
      HowTo12:"気になるデータを見つけよう",
      HowTo13:"ユーザーを探してみる",
      HowTo14:"気に入ったユーザーはフォローできます。フォローしたユーザーのタイムラインはタイムラインで追うことができます。フォローしているユーザーがデータを投稿すると通知も届きます。",
      HowTo15:"ユーザーを探す",
      HowTo16:"広告を出そう！",
      HowTo17:"アカウントを作れば、誰でも広告の出稿が可能です。Tatamiには世界中から多種多様なユーザーが集まっています。インプレッション型とクリック型から最適な方法を選択して、Tatamiのプラットフォームを最大限活用しましょう！",
      HowTo18:"Tatamiに広告を出す",
      HowTo19:"ログインしてください",
      LikedPosts1:"ゲストユーザー",
      LikedPosts2:"XXXX-XX-XX",
      LikedPosts3:"投稿",
      LikedPosts4:"フォロー中のBook",
      LikedPosts5:"いいね",
      LikedPosts6:"データ",
      LikedPosts7:"投稿するにはログインしてください",
  
      Login1:"'Your Gmail account was not verified.",
      Login2:"LoginFailed",
      Login3:"RegisterFailed",
      Login4:"Tatami",
      Login5:"Tatamiに ",
      Login6:"ログイン",
      Login7:"アカウントを ",
      Login8:"新規作成",
      Login9:"Your Email Address",
      Login10:"Password",
      Login11:"自動でログインする",
      Login12:"パスワードをお忘れですか？",
      Login13:"ログイン",
      Login14:"メールアドレス",
      Login15:"パスワード",
      Login16:"パスワード（確認）",
      Login17:"規約に同意する",
      Login18:"アカウントを作成",
      Login19:"Sign in with Google",
      Login20:"初めてTatamiをご利用される方はこちら",
      Login21:"アカウントをお持ちの方はこちら",
      Login22:"Googleアカウントで始める",
      Login23:"Emailで始める",
      Logout1:"ログアウト",
      Logout2:"ログアウト",
      Logout3:"戻る",
      DeleteAccount1:"退会",
      DeleteAccount2:"アカウントを削除",
      DeleteAccount3:"戻る",

      // Mono1:"Bookをフォローしました。",
      // Mono2:"リンクをコピーしました。",
      // Mono3:"Bookを保存",
      // Mono4:"保存済みのBook",
      // Mono5:"Bookをフォロー",
      // Mono6:"フォロー済み",
      // Mono7:"シェア",
      // Mono8:"総シンパシー",
      // Mono9:"0%",
      // Mono10:"件のPost",
      // Mono11:"これまでの議論",
      // Mono12:"Bookの重要論点",
      // Mono13:"保存すると続きが見られます",
      // Mono14:"このページから",
      // Mono15:"このBook生成に使用したデータ",
      // // Mono16:"保存すると全データを取得できます",
      // Mono16:"保存すると全データを取得できます",
      // Mono17:"Tatami AI",
      // Mono18:"created",
      // Mono19:"次の更新まで",
      // Mono20:"投稿するにはログインしてください",
      // Mono21:"TatamiによるBookレポート",
      // Mono22:"Tatamiユーザーの意見",
      // Mono23:"ポスト",
      // Mono24:"保存すると取得可能できる情報（一部）",
      // Mono25:"このBookへのコメント",
      // Mono26:"※初回更新まではTatamiが世論を総括した内容が表示されています。",
      Mono1:"積読に追加しました。",
      Mono2:"リンクをコピーしました。",
      Mono3:"本棚に追加してダウンロード",
      Mono4:"本棚に追加済み",
      Mono5:"積読に追加",
      Mono6:"積読中",
      Mono7:"シェア",
      Mono8:"役に立った！",
      Mono9:"0%",
      Mono10:"件のPost",
      Mono11:"概要",
      Mono12:"Bookの重要論点",
      Mono13:"本棚に追加してダウンロード",
      Mono14:"このページから",
      Mono15:"このBook生成に使用したデータ",
      // Mono16:"保存すると全データを取得できます",
      Mono16:"本棚に追加してダウンロード",
      Mono17:"Tatami AI",
      Mono18:"created",
      Mono19:"次の更新まで",
      Mono20:"投稿するにはログインしてください",
      Mono21:"Book",
      Mono22:"Tatamiユーザーの意見",
      Mono23:"ポスト",
      Mono24:"Tatami AIの情報",
      Mono25:"このBookへのコメント",
      Mono26:"※初回更新まではTatamiが世論を総括した内容が表示されています。",
      Mono27:"ここから意見をポスト！",
      Mono28:"Bookが進化します",
      Mono29:"関連",
      Mono30:"元データの投稿者",
      Mono31:"フォロー済みのページです",
      OwningEach1:"総役に立った！",
      OwningPage1:"議論のポイント",
      OwningPage2:"Book理解のポイント",
      OwningPage3:"Post",
      OwningPage4:"This Month Impression",
      OwningPage5:"Female Access",
      OwningPage6:"Male Access",
      OwningPage7:"Tatamiによる回答",
      OwningPages1:"蔵書一覧",
      OwningPages2:"Bookを検索",
      OwningPages3:"あなたの本棚",
      PostedData1:"投稿",
      PostedData2:"フォロー中のBook",
      PostedData3:"いいね",
      PostedData4:"データ",
      Postview1:"この投稿を削除しますか？",
      Postview2:"はい",
      Postview3:"キャンセル",
      Postview4:"Bookの役立ち度：",
      Postview5:"コメントを閉じる",
      Postview6:"Bookの役立ち度：",
      Postview7:"コメントを閉じる",
      Postview8:"ページが見つかりません",
      Postview9:"Bookを本棚に追加しました！",
      PostviewforPage1:"この投稿を削除しますか？",
      PostviewforPage2:"はい",
      PostviewforPage3:"キャンセル",
      PostviewforPage4:"Bookの役立ち度：",
      PostviewforPage5:"コメントを閉じる",
      PostviewforPage6:"Bookの役立ち度：",
      PostviewforPage7:"コメントを閉じる",
      PostviewforPage8:"ページが見つかりません",
      ReccomendPosts:"",
      Recommend1:"Tatamiとは？",
      Recommend2:"カテゴリー",
      Recommend3:"全て表示",
      Recommend4:"タグ",
      Recommend5:"全て表示",
      Recommend6:"Tatami AI",
      Recommend7:"投稿データからBookを生成中...",
      Recommend8:"次の生成まで",
      Recommend9:"Bookを探す",
      Recommend10:"Tatami AI",
      Recommend11:"により分類・統合",
      Recommend12:"されています",
      Recommend13:"Bookを検索",
      Recommend14:"Bookを検索",
      Recommend15:"新着Book",
      Recommend16:"選択された条件に役立ちそうなBook",
      Recommend17:"選択された条件に役立ちそうなBook",
      Recommend18:"選択された条件に役立ちそうなBook",
      Recommend19:"ここからデータをポスト！",
      Recommend20:"Tatami AIがBook化します",
      RecommendEach1:"総役に立った！",
      Recommenduser1:"ヒットしたユーザー",
      Recommenduser2:"新しいユーザー",
      Recommenduser3:"ユーザーを検索",
      Recommenduser4:"ユーザーを探す",
      Request:"",
      ResetPassword1:"エラー！ ネットワーク接続を確認する。",
      ResetPassword2:"両方のパスワードが一致しません。",
      ResetPassword3:"エラー！ ネットワーク接続を確認する。",
      ResetPassword4:"エラー！ ネットワーク接続を確認する。",
      ResetPassword5:"新しいパスワードを入力してください",
      ResetPassword6:"パスワードをリセットします。",
      ResetPassword7:"パスワードがリセットされました",
      ResetPassword8:"電子メールを検証しています",
      ResetPassword9:"新しいパスワードを選択してください。",
      ResetPassword10:"Enter new password",
      ResetPassword11:"Confirm new password",
      ResetPassword12:"送信",
      ResetPassword13:"あなたのパスワードはリセットされました",
      DataDownload1:"ダウンロードが完了しました",
      DataDownload2:"ダウンロード中にエラーが発生しました",
      DataDownload3:"クリックでダウンロード",
      DataDownload4:"クリックで元データへ移動（外部ページへ遷移します）",

// {t('Recommend1')}
      Settings1:"設定",
      Settings2:"一般",
      Settings3:"アカウント情報",
      Settings4:"Tatamiの使い方",
      Settings5:"言語設定",
      Settings6:"収益",
      Settings7:"広告を出す",
      Settings8:"口座情報登録",
      Settings9:"その他",
      Settings10:"ログアウト",
      Settings11:"退会",
      Settings12:"特定商取引法に基づく表記",
      Settings13:"通知設定",
      SpecificUserpage:"",
      Success1:"口座情報登録が",
      Success2:"完了しました",
      Userpage1:"ゲストユーザー",
      Userpage2:"タイムライン",
      Userpage3:"積読",
      Userpage4:"いいね",
      Userpage5:"データ",
      Userpage6:"に参加",
      Userpage7:"Book",
      Users:"",
      Verify1:"続行するにはメールボックスをご確認してください。",
      Verify2:"にメールを送信しました。メールを確認し、提供されたリンクをクリックしてください。",
      Verify:"",


      // 他の日本語の翻訳もここに
    }
  }
  // 他の言語も追加可能
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false // 必要に応じて追加
    }
  });

export default i18n;
