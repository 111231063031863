import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Tooltip, Fab} from "@material-ui/core";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import RecommendEach from "./RecommendEach";
import { ApiContext } from "../context/ApiContext";
import { Link, useLocation, useHistory } from "react-router-dom";
import AttributeEach from "../components/AttributeEach";
import { useCookies } from "react-cookie";
// import CategoriesModal from "../components/CategoriesModal";
import AttributesModal from "../components/AttributesModal";
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@mui/icons-material/Translate';
import { SnackbarContext } from "../context/SnackbarContext";
import i18n from '../i18n'; 
import {
  Card,
} from "@material-ui/core";
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Clock from "../components/Clock";
import HowToModal from "../components/HowToModal";
import DataModal from "../components/DataModal";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    marginBottom: "1rem",
    maxHeight: 30
  },
  cardContent: {
    minHeight: "130px",
  },
  media: {
    height: 140,
  },
  cssloadLoader: {
    position: 'relative',
    width: '80px',
    height: '80px',
    margin: '0 10px',
    perspective: '780px',
    top:'7px',
  },
  cssloadLoader2: {
    position: 'relative',
    width: '30px',
    height: '30px',
    margin: '0 10px',
    perspective: '780px',
    left: '2px',
  },
  cssloadInner: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    borderRadius: '50%',
    '&.cssloadOne': {
      left: '0%',
      top: '0%',
      animation: '$cssloadRotateOne 4s linear infinite',
      borderBottom: '3px solid #fff0f5',
    },
    '&.cssloadTwo': {
      right: '0%',
      top: '0%',
      animation: '$cssloadRotateTwo 4s linear infinite',
      borderRight: '3px solid #fff0f5',
    },
    '&.cssloadThree': {
      right: '0%',
      bottom: '0%',
      animation: '$cssloadRotateThree 4s linear infinite',
      borderTop: '3px solid #fff0f5',
    },
  },
  '@keyframes cssloadRotateOne': {
    '0%': {
      transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
    },
    '100%': {
      transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
    },
  },
  '@keyframes cssloadRotateTwo': {
    '0%': {
      transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
    },
    '100%': {
      transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
    },
  },
  '@keyframes cssloadRotateThree': {
    '0%': {
      transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
    },
    '100%': {
      transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
    },
  },
  bodyStyle: {
    background: 'linear-gradient(-45deg,#79AC78,#90b090,#477451)',
    backgroundSize: '400% 400%',
    animation: '$gradient 20s ease infinite',
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: '0% 50%'
    },
    "50%": {
      backgroundPosition: '100% 50%'
    },
    "100%": {
      backgroundPosition: '0% 50%'
    }
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  line: {
    display: 'block',
    position: 'relative',
    '&::after': {
      background: 'linear-gradient(to right, #eee 0%, #eee 50%, #eee 100%)',
      content: '""',
      display: 'block',
      height: '1px',
      width: 0,
      transform: 'translateX(-50%)',
      transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
      position: 'absolute',
      bottom: 0,
      left: '50%',
    },
    '&.is-animated::after': {
      width: '100%',
    },
  },
  scroll: {
    display: 'inline-block',
    paddingTop: '70px',
    position: "absolute",
    width: "100%",
    zIndex: "999",
    left: "0px",
    bottom: "-50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '&::before': {
      animation: '$scroll 3.5s infinite',
      border: 'solid #79ac78',
      borderWidth: '0 0 1px 1px',
      content: '""',
      display: 'inline-block',
      margin: 'auto',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      transform: 'rotate(-45deg)',
      width: '20px',
      height: '20px',
      opacity: 0,
    },
  },
  '@keyframes scroll': {
    '0%': {
      transform: 'rotate(-45deg) translate(0, 0)',
      opacity: 0,
    },
    '40%': {
      opacity: 1,
    },
    '80%': {
      transform: 'rotate(-45deg) translate(-30px, 30px)',
      opacity: 0,
    },
    '100%': {
      opacity: 0,
    },
  },
  bg: {
    '& $bgWrap': {
      position: 'relative',
      display: 'inline-block',
      marginTop: '5px',
    },
    '&.is-animated $bgWrap::before': {
      animation: '$bgAnimation 2.6s cubic-bezier(0.22, 1, 0.36, 1) forwards',
      background: 'linear-gradient(to right, #79ac78 0%,#3b79cc 50%,#42d3ed 100%)',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      transformOrigin: 'left center',
    },
    '& $bgWrap $inn': {
      color: '#fff',
      display: 'inline-block',
      fontSize: '13px',
      fontWeight: 'bold',
      padding: '4px 10px',
      position: 'relative',
      zIndex: 1,
    },
  },
  bgWrap: {},
  inn: {},
  '@keyframes bgAnimation': {
    '0%': {
      opacity: 0,
      transform: 'scaleX(0) translateX(-5%)',
    },
    '30%': {
      transform: 'scaleX(1) translateX(0)',
      opacity: 1,
    },
    '100%': {
      transform: 'scaleX(1) translateX(0)',
      opacity: 1,
    },
  },
  gentleShake: {
    animation: `$gentleShake 0.5s 3`, // Add gentle shaking animation
  },
  tooltip: {
    position: "absolute",
    bottom: "70px", // Adjust according to your button's position
    backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent white
    color: "#00FF00", // Futuristic green color
    padding: "10px 15px",
    borderRadius: "10px",
    fontSize: "14px",
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
    boxShadow: "0 0 10px rgba(0, 255, 0, 0.5)",
    border: "1px solid rgba(0, 255, 0, 0.5)",
    backdropFilter: "blur(5px)",
    left: "50%",
    transform: "translateX(-50%)", // Center horizontally
    [theme.breakpoints.down('xs')]: { // Media query for small screens
      left: "35%",
      transform: "translateX(-50%)", // Ensure it stays centered
    },
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "-20px", /* Adjust if needed */
      left: "50%",
      transform: "translateX(-50%)",
      borderWidth: "10px",
      borderStyle: "solid",
      borderColor: "rgba(255, 255, 255, 0.1) transparent transparent transparent", /* Arrow with semi-transparent white fill */
    },
  },
  tooltipShow: {
    opacity: 1,
    animation: `$fadeInUp 0.5s ease-in-out`,
  },
  "@keyframes fadeInUp": {
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
  },

  "@keyframes gentleShake": {
    "0%": { transform: "translateX(1px)" },
    "10%": { transform: "translateX(-1px)" },
    "20%": { transform: "translateX(-2px)" },
    "30%": { transform: "translateX(2px)" },
    "40%": { transform: "translateX(1px)" },
    "50%": { transform: "translateX(-1px)" },
    "60%": { transform: "translateX(-2px)" },
    "70%": { transform: "translateX(2px)" },
    "80%": { transform: "translateX(-1px)" },
    "90%": { transform: "translateX(1px)" },
    "100%": { transform: "translateX(1px)" },
  },

  DataButton: {
    background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
    backgroundSize: '400% 400%',
    animation: '$gradient 10s ease infinite',
    },
  DataButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "350px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: {  // メディアクエリ
        left: '150px',
      },
  },
}));

const Recommend = () => {
  const { pageattribute, pagesforintattribute, pageWithScrollJA, pageWithScrollEN, userintatt, searchPages, getAttribute, getPagesForIntAttribute, profile } = useContext(ApiContext);
  const { t } = useTranslation();
  const { newSnack } = useContext(SnackbarContext);
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [showpages, setShowpages] = useState([]);
  const [pagesbyscroll, setPagesbyscroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [noMoreData, setNoMoreData] = useState(false);
  // const [isShowCategoriesModal, setShowCategoriesModal] = useState(false);
  const [isShowAttributesModal, setShowAttributesModal] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [showTooltip, setShowTooltip] = useState(false);
  const [shake, setShake] = useState(false);
  const [isShowDataModal, setShowDataModal] = useState(false);

  const loaderRef = useRef(null);
  const prevCountRef = useRef(count);
  const location = useLocation();
  const history = useHistory();

  const [isShowHowToModal, setShowHowToModal] = useState(false);

const handleHowToModalClose = () => {
  setShowHowToModal(false);
};

const [animate, setAnimate] = useState(false);

useEffect(() => {
  setAnimate(true);
  setPagesbyscroll([]);
}, []);

useEffect(() => {
  if (pagesbyscroll.length === 0) {  // 初回のみ
    setCount(0);
    setPagesbyscroll([]);
  }
}, []);

const handleDataClick = () => {
  if (!profile.userProfile) {
    newSnack("error", t('Header14'));
    return;
  }
  setShowDataModal(true);
}

const handleDataModalClose = () => {
  setShowDataModal(false);
}




useEffect(() => {
  const params = new URLSearchParams(location.search);
  const attid = params.get("attid");

  if (attid) {
    const fetchPages = async () => {
      try {
        const pages = await getPagesForIntAttribute(attid);
        setShowpages(pages); // 結果を直接showpagesにセット
      } catch (error) {
        console.error("Failed to fetch pages for attribute:", error);
      }
    };
    fetchPages();
  } else if (inputValue.trim() !== '') {
    params.delete("attid");
    history.replace({ search: params.toString() });

    searchPages(inputValue).then(result => {
      setShowpages(result);
    });
  } else {
    setShowpages([]);
  }
}, [location.search, inputValue]);





// 検索フィールドの変更を監視
useEffect(() => {
  if (inputValue.trim() !== '') {
    const params = new URLSearchParams(location.search);
    params.delete("attid");
    history.replace({ search: params.toString() });

    searchPages(inputValue).then(result => {
      setShowpages(result);
    });
  } else {
    setShowpages([]);
  }
}, [inputValue]);

// デフォルトのページを取得
useEffect(() => {
  if (!inputValue && !location.search.includes("attid")) {
    setCount(0);
    setPagesbyscroll([]);
  }
}, [inputValue, location.search, language]);

  // 無限スクロールの設定
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    });
    if (loaderRef.current) observer.observe(loaderRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const fetchAttributes = async () => {
      await getAttribute(language);
    };
    fetchAttributes();
  }, [language]);

  const handleInputChange = async (e) => {
    setInputValue(e.target.value);
  };

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !isLoading && !noMoreData) {
      setCount(prev => prev + 1);
    }
  };

  // const handleCategoriesClick = () => {
  //   setShowCategoriesModal(true);
  // };

  const handleAttributesClick = () => {
    setShowAttributesModal(true);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault(); // フォーム送信を防止（ページリロード防止）
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('preferredLanguage', newLanguage);
    setCount(0);
    setPagesbyscroll([]);
    i18n.changeLanguage(newLanguage);
  };

  const fetchData = async (currentCount) => {
    setIsLoading(true);
    const fetchFunc = language === "ja" ? pageWithScrollJA : pageWithScrollEN;
    const result = await fetchFunc(currentCount);

    if (result.length === 0) {
      setNoMoreData(true);
    } else {
      setPagesbyscroll(prevItems => [...prevItems, ...result]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (prevCountRef.current !== count || count === 0) {
      prevCountRef.current = count;
      fetchData(count);
    }
  }, [count, language]);


  useEffect(() => {
    setShake(true);
    setShowTooltip(true);
    const shakeTimer = setTimeout(() => setShake(false), 1000); // 3 shakes * 0.5s
    const tooltipTimer = setTimeout(() => setShowTooltip(false), 3000);
    return () => {
      clearTimeout(shakeTimer);
      clearTimeout(tooltipTimer);
    };
  }, []);

  const loaderComponent = (
    <div className={classes.cssloadLoader}>
      <div className={clsx(classes.cssloadInner, 'cssloadOne')}></div>
      <div className={clsx(classes.cssloadInner, 'cssloadTwo')}></div>
      <div className={clsx(classes.cssloadInner, 'cssloadThree')}></div>
    </div>
  );

  const defaultPages = (pagesbyscroll || []).map((page, index) => (
    <RecommendEach key={`page-${page.id}-${index}`} pageData={page} />
  ));
  
  const listpages = (showpages || []).map((page, index) => (
    <RecommendEach key={`search-page-${page.id}-${index}`} pageData={page} />
  ));

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <div className="main-content right-chat-active">
        {/* <CategoriesModal
          isVisible={isShowCategoriesModal}
          handleClose={() => setShowCategoriesModal(false)}
          lan={language}
        /> */}
        <AttributesModal
          isVisible={isShowAttributesModal}
          handleClose={() => setShowAttributesModal(false)}
          lan={language}
        />
        {/* <HowToModal
          isVisible={isShowHowToModal}
          handleClose={handleHowToModalClose}
        /> */}
        <DataModal
        isVisible={isShowDataModal}
        handleClose={handleDataModalClose}
      />
                <div className={ classes.DataButtonContainer }>
                {showTooltip && (
              <div className={`${classes.tooltip} ${showTooltip ? classes.tooltipShow : ''}`}>
                {t('Recommend19')} <br />
                {t('Recommend20')}
              </div>
            )}
                <Tooltip title="Post Data" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={handleDataClick}
                    >
                    <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                        <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  )}
                </Tooltip>
              </div>
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
              {!profile.id ? 
                <Link to="/howto">
                  <span className="pointer font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-gold-gradiant d-inline-block text-white shadow-sm w-100 mb-2 d-flex">
                    <i className="feather-chevrons-right text-white btn-round-sm font-sm"></i>
                    <h4 className="font-xss text-white fw-600 mt-2">
                      {t('Recommend1')}
                    </h4>
                  </span>
                </Link>:<></>}

                {/* <span className="card font-xsssss fw-700 pe-2 lh-32 text-uppercase rounded-3 ls-2 bg-green-gradiant text-white shadow-sm h125 border-0 w-100 mb-2 text-center">
                  <div className="d-flex ms-auto me-auto mt-2">
                    <div>
                      <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                      </div>
                      <h2 className="fw-600 font-xsssss mt-3 text-white-pink mb-0">{t('Recommend6')}</h2>
                    </div>
                    <div>
                      <h4 className="font-xssss text-white-pink fw-600 mt-2 mb-1">
                        {t('Recommend7')}
                      </h4>
                      <h4 className="font-xssss text-white-pink fw-600 mt-0 mb-1">
                        {t('Recommend8')}
                      </h4>
                      <Clock />
                    </div>
                  </div>
                </span> */}

                <span className={clsx(classes.bodyStyle, "card font-xsssss fw-700 pe-3 ps-3 pt-2 lh-32 text-uppercase rounded-3 ls-2 text-white shadow-sm border-0 w-100 mb-2 text-center")}>
                  <div className={`${classes.line} ${animate ? "is-animated" : ""} mb-2 font-xss`}>{t('Recommend9')}</div>
                  <div className="fw-600 ms-auto lh-32 text-uppercase rounded-3 ls-2 position-absolute text-center top-0 mb-5 mt-1 me-2 right-0">
                    {language === "ja" ?
                      <TranslateIcon className="pointer" onClick={() => handleLanguageChange("en")} /> :
                      <TranslateIcon className="pointer" onClick={() => handleLanguageChange("ja")} />
                    }
                  </div>
                  {/* <div className="fw-600 ms-auto lh-32 text-uppercase rounded-3 ls-2 position-absolute text-center top-15 mb-5 me-2 right-0">
                    <div className={classes.flexContainer}>
                      <div className={classes.cssloadLoader2}>
                        <div className={`${classes.cssloadInner} cssloadOne`}></div>
                        <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                        <div className={`${classes.cssloadInner} cssloadThree`}></div>
                      </div>
                    </div>
                    <h2 className="fw-600 font-xssssss mt-1 text-white-pink mb-0">{t('Recommend10')}</h2>
                    <h2 className="fw-600 font-xssssss mt-0 text-white-pink mb-0">{t('Recommend11')}</h2>
                    <h2 className="fw-600 font-xssssss mt-0 text-white-pink mb-0">{t('Recommend12')}</h2>
                  </div> */}

                  {/* <div className="card w-75 border-white-pink bg-transparent shadow-xss rounded-xxl border-light-md ps-2 pe-2 pt-1 pb-1 mb-2"> */}
                    <div className="ard-body d-flex align-items-center ps-1 pe-1 pt-1 pb-2">
                    <form className="pt-0 pb-0 ms-auto w-100" onSubmit={handleSearchSubmit}>
  <div className="search-form-2 ms-1">
    <i className="ti-search font-xss"></i>
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      className="form-control font-xss text-grey-600 mb-0 bg-greylight theme-dark-bg border-0"
      placeholder={t('Recommend13')}
    />
  </div>
</form>
                    </div>
                  {/* </div> */}

                  <div className="card w-100 bg-transparent border-white-pink shadow-xss rounded-xxl border-light-md ps-2 pe-2 pt-1 pb-1 mb-2">
                    <div className="ard-body d-flex align-items-center ps-3 pe-3 pt-1 pb-1">
                      <h4 className="fw-700 mb-0 font-xssss text-white-pink">
                        {t('Recommend4')}
                      </h4>
                      <a onClick={() => handleAttributesClick()} className="fw-600 ms-auto font-xssss text-white-pink pointer">
                        {t('Recommend5')}
                      </a>
                    </div>
                    <div className="row justify-content-center mb-0 pe-1 ps-1">
                      {pageattribute && pageattribute.slice(0, 8).map((attribute, index) => (
                        <AttributeEach key={`attribute-${attribute.id}-${index}`} attributeData={attribute} />
                      ))}
                    </div>
                  </div>
                </span>

                {
                  !userintatt && !inputValue &&
                  <div className={`${classes.bg} mb-2 ${animate ? 'is-animated' : ''}`}>
                    <span className={classes.bgWrap}>
                      <span className={`${classes.inn}`}>{t('Recommend15')}</span>
                    </span>
                  </div>
                }

                {
                  !userintatt && inputValue &&
                  <div className={`${classes.bg} mb-2`}>
                    <span className={classes.bgWrap}>
                      <span className={`${classes.inn}`}>{t('Recommend16')}</span>
                    </span>
                  </div>
                }

                {
                  userintatt && !inputValue &&
                  <div className={`${classes.bg} mb-2`}>
                    <span className={classes.bgWrap}>
                      <span className={`${classes.inn}`}>{t('Recommend17')}</span>
                    </span>
                  </div>
                }

                <div className="row justify-content-center ps-3 pe-3">
                <div className="row justify-content-center ps-3 pe-3">
                {listpages}
                {!inputValue && !location.search.includes("attid") && defaultPages}
              </div>

                </div>

                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>
                {isLoading && (
                  <div className="card-body p-0 mb-3">
                    <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                      <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="p-4 mb-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Recommend;
