import React, {  Fragment, useEffect, useRef } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';
import { useContext, useState } from 'react';
import { ApiContext } from '../context/ApiContext';
// import Profiledetail from '../components/Profiledetail';
// import UserpagePicture from "../components/UserpagePicture";
// import FollowEach from "../components/FollowEach";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
// import RecommendEach from "./RecommendEach";
// import ProfileRecommender from "../components/ProfileRecommender";
import OwningEach from "./OwningEach";
import Lightbox from "react-image-lightbox";
import NoOwingPageYet from "../components/NoOwingPageYet";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import HowToModal from "../components/HowToModal";


const useStyles = makeStyles((theme) => {
  return {
  line: {
    display: 'block',
    position: 'relative',
    '&::after': {
      background: 'linear-gradient(to right,#79ac78 0%,#79ac78 50%,#79ac78 100%)',
      content: '""',
      display: 'block',
      height: '2px',
      width: 0,
      transform: 'translateX(-50%)',
      transition: '2s cubic-bezier(0.22, 1, 0.36, 1)',
      position: 'absolute',
      bottom: 0,
      left: '50%',
    },
    '&.is-animated::after': {
      width: '100%',
    },
  },
}
});
const OwningPages = () => {
  const { t } = useTranslation();
    const { profile, 
        // monoposts,
        owningpage, getOwningPage, owningPageWithScroll,searchOwnedPages,fetchOwningAndRelatedPagesWithScroll
        //  profiles, followingpage
        } =  useContext(
        ApiContext
    );
    // const posts = monoposts.filter(item =>{return item.userPost === profile.userProfile})
    const [cookies] = useCookies(["current-token"]);
    const [inputValue, setInputValue] = useState();
    const [showpages, setShowpages] = useState();
    const [pagesbyscroll, setPagesbyscroll] = useState([])
    const [isLoading, setIsLoading] = useState(0);
    const [count, setCount] = useState(0);
    const [noMoreData, setNoMoreData] = useState(false);

    const loaderRef = useRef(null);

    const handleObserver = (entities, observer) => {
      const target = entities[0];

      if (target.isIntersecting && !isLoading && !noMoreData) {
          setCount((prev) => prev + 1);
      }
    };

    const classes = useStyles();
    const [animate, setAnimate] = useState(false);


    

    useEffect(() => {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
  
      const observer = new IntersectionObserver(handleObserver, options);
  
      if (loaderRef.current) {
        observer.observe(loaderRef.current);
      }
  
      return () => {
        observer.disconnect();
      };
    }, []);

    const handleInputChange = async (e) => {
      if (!profile.id) return; // プロフィールが存在しない場合は処理を中止
    
      const value = e.target.value;
      setInputValue(value);
    
      if (value.trim() === "") {
        setShowpages([]);
      } else {
        const result = await searchOwnedPages(value);
        setShowpages(result);
      }
    };
    
    


    const search = (value) => {
      if (value === "") {
        setShowpages(owningpage);
        return
      }
      const searchedpages = owningpage.filter(
        (page) => page.title.toUpperCase().indexOf(value.toUpperCase()) > -1
      );
      setShowpages(searchedpages)
    };


    const defaultPages =
        pagesbyscroll &&
        pagesbyscroll.map(page =>
            <OwningEach
            key={page.id}
            pageData ={page}/>)

    const listpages =
        showpages &&
        showpages.map(page =>
            <OwningEach
            key={page.id}
            pageData ={page}/>)

    const fetchData = async () => {
    
      setIsLoading(true);
      const result = await fetchOwningAndRelatedPagesWithScroll(count);
      if (result.length === 0) {
        setNoMoreData(true);
      } else {
        setPagesbyscroll(prevItems => [...prevItems, ...result]);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      if (profile.id) {
        // ログイン情報が取れたら、無限スクロールを初期化
        setCount(0);
        setNoMoreData(false);
        setPagesbyscroll([]);
      }
    }, [profile.id]);
            


    useEffect(() => {
      // profile.id が取得されているかどうかをチェック
      if (profile.id && !inputValue) {
        fetchData();
      }
    }, [count, inputValue]);
    

        
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isShowHowToModal, setShowHowToModal] = useState(false);
    const handleShowHowToModalClick = () => {
      setShowHowToModal(true)
    }
    const handleHowToModalClose = () => {
      setShowHowToModal(false);
    }
    const handleSearchSubmit = (e) => {
      e.preventDefault(); // フォーム送信を防止（ページリロード防止）
    };
  
    useEffect(() => {
      const hasVisited = localStorage.getItem('hasVisited');
      setPagesbyscroll([]);
      setAnimate(true);
  
      if (!hasVisited) {
        setShowHowToModal(true);
        localStorage.setItem('hasVisited', 'true');
      }
    }, []);
  

    return (
      <Fragment>
      <Header />
      <Leftnav />
      <div className="main-content right-chat-active">
      <HowToModal
        isVisible={isShowHowToModal}
        handleClose={handleHowToModalClose}
      />
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 ">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                  <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
                  {t('OwningPages3')}
                  <form className="pt-0 pb-0 ms-auto" onSubmit={handleSearchSubmit}>
  <div className="search-form-2 ms-1">
    <i className="ti-search font-xss"></i>
    <input
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
      placeholder={t('OwningPages2')}
    />
  </div>
</form>
                                    {/* <a href='/' className='btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3'><i className='feather-filter font-xss text-grey-500'></i></a> */}
                                  </h2>
                                </div>




                            </div>
              
                    
                            <div className="col-xl-12 col-xxl-12 col-lg-12 ps-2">
                            <div className={`${classes.line}  ${animate ? "is-animated" : ""} font-xssss text-center text-current fw-600 mt-0 ms-2 mb-2`}>{t('OwningPages1')}</div>
                            
                            {!profile.id ?
                                <div className='card w-100 shadow-xss rounded-xxl border-0 mb-3'>
                                <LoginComponent />
                                </div>:
                                <></>
                                }
                              <div className="row ps-2 pe-2">
                                {profile.id && (
                                  <>
                                    {inputValue ? listpages : defaultPages}
                                    {defaultPages.length === 0 && <NoOwingPageYet />}
                                  </>
                                )}
                              </div>


                                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>

                                {isLoading?
                                <div className="card-body p-0 mb-3">
                                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                                <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                                    <div className="stage">
                                    <div className="dot-typing"></div>
                                    </div>
                                </div>
                                </div>
                                </div>  :
                                <></>}
                                <div className="p-4 mb-3">
                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter /> 
        </Fragment>
    );
}

export default OwningPages;