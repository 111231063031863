import React, { StrictMode, useEffect} from "react";
import { CookiesProvider } from "react-cookie";
import ApiContextProvider from "./context/ApiContext";
import './i18n'

import { GoogleOAuthProvider } from '@react-oauth/google';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';


import "./main.scss";

// ユーザー毎の機能
import Home from "./pages/Home";
import Recommend from "./pages/Recommend";
import Userpage from "./pages/Userpage";
import Recommenduser from "./pages/Recommenduser";
import Request from "./pages/Request";
import Users from "./pages/Users";
import Following from "./pages/Following";
import LikedPosts from "./pages/LikedPosts";

// import MutualFollowing from "./pages/MutualFollowing";
import PostedData from "./pages/PostedData";
import Followed from "./pages/Followed";
import FollowingPageList from "./pages/FollowingPageList";
import GetPage from "./pages/GetPage";
import GotPage from "./pages/GotPage";
import OwningPage from "./pages/OwningPage";
import OwningPages from "./pages/OwningPages";

// 内容
import Mono from "./pages/Mono";
import SpecificUserpage from "./pages/SpecificUserpage";
import SpecificUserpageFollowed from "./pages/SpecificUserpageFollowed";
import SpecificUserpageFollowing from "./pages/SpecificUserpageFollowing";

// 初期設定
import Verify from "./pages/Verify";
import Login from "./pages/Login";
import EmailVerify from "./pages/EmailVerify";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Logout from './pages/Logout';
import DeleteAccount from "./pages/DeleteAccount";

// 設定
import Settings from "./pages/Settings";
import Account from "./pages/Account";
import BankAccount from "./pages/BankAccount";
import AdApplication from "./pages/AdApplication";
import HowTo from "./pages/HowTo";
import ChangeLanguage from "./pages/ChangeLanguage";

// アナリティクス
import Analytics from "./pages/Analytics";
import Success from "./pages/Success";
import NotificationSetting from "./pages/NotificationSetting";


import { BrowserRouter, Route } from "react-router-dom";


import SnackbarProvider from "./context/SnackbarContext";
import NotationsSCTA from "./pages/Notations-SCTA";
import SpecificUserpageBook from "./pages/SpecificUserpageBook";
import UserpageBook from "./pages/UserpageBook";



const App = () => {

    // トラッキングIDを環境変数から取得
    const trackingId = process.env.REACT_APP_GA_CODE;

    useEffect(() => {
      // Google Analytics を初期化
      ReactGA.initialize(trackingId);
      // 初期ページビューをトラッキング
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [trackingId]);
  
    // ルート変更があるたびにページビューをトラッキングするための関数
    const trackPageView = (location) => {
      ReactGA.set({ page: location.pathname }); // ページ情報をセット
      ReactGA.pageview(location.pathname + location.search); // ページビューをトラッキング
    };

  return (
    <SnackbarProvider>
      <BrowserRouter basename="/" onUpdate={() => trackPageView(window.location)}>
        <ApiContextProvider>
          <StrictMode>
            <CookiesProvider>
              <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENTID}>
              <Helmet>

                </Helmet>
                <Route exact path="/login" component={Login} />
                <Route exact path="/verify/:id/:key" component={EmailVerify} />
                <Route exact path="/please-verify" component={Verify} />
                <Route exact path="/forgot" component={ForgotPassword} />
                <Route exact path="/reset-password/:id/:key" component={ResetPassword} />
                <Route exact path="/home" component={Home} />
                <Route exact path="/" component={Home} />
                <Route exact path="/recommend" component={Recommend} />
                <Route exact path="/userpage" component={Userpage} />
                <Route exact path="/recommenduser" component={Recommenduser} />
                <Route exact path="/users" component={Users} />
                <Route exact path="/mono/:pageid" component={Mono} />
                <Route exact path="/request" component={Request} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/account" component={Account} />
                <Route exact path="/analytics" component={Analytics} />
                <Route exact path="/user/:userid" component={SpecificUserpage} />
                <Route exact path="/following" component={Following} />
                {/* <Route exact path="/mutualfollowing" component={MutualFollowing} /> */}
                <Route exact path="/posteddata" component={PostedData} />
                <Route exact path="/followed" component={Followed} />
                <Route exact path="/followingpagelist" component={FollowingPageList} />
                <Route exact path="/getpage" component={GetPage} />
                <Route exact path="/gotpage" component={GotPage} />
                <Route exact path="/owningpage/:pageid" component={OwningPage} />
                <Route exact path="/owningpages" component={OwningPages} />
                <Route exact path="/bankaccount" component={BankAccount} />
                <Route exact path="/success" component={Success} />
                <Route exact path="/likedposts" component={LikedPosts} />
                <Route exact path="/adapplication" component={AdApplication} />
                <Route exact path="/howto" component={HowTo} />
                <Route exact path="/languagesetting" component={ChangeLanguage} />
                <Route exact path="/deleteaccount" component={DeleteAccount} />
                <Route exact path="/Notations-SCTA" component={NotationsSCTA} />
                <Route exact path="/userfollowing" component={SpecificUserpageFollowing} />
                <Route exact path="/userfollowed" component={SpecificUserpageFollowed} />
                <Route exact path="/notificationsetting" component={NotificationSetting} />
                <Route exact path="/specificuserbook" component={SpecificUserpageBook} />
                <Route exact path="/userpagebook" component={UserpageBook} />
              </GoogleOAuthProvider>
            </CookiesProvider>
          </StrictMode>
        </ApiContextProvider>
      </BrowserRouter>
    </SnackbarProvider>
  );
};

export default App;
