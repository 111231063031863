import React, {
  useState, 
  Fragment, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Tooltip, Fab } from "@material-ui/core";
import { AddComment } from "@material-ui/icons";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import { ApiContext } from '../context/ApiContext'
import { useEffect } from "react";
import DataDownload from "../components/DataDownload";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import NoPostYet from "../components/NoPostYet";
import PostviewforPage from "./PostviewforPage";
import Createpost from "../components/Createpost";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  PostButton: {
      backgroundColor: green.A700,
      "&:hover": {
          backgroundColor: green.A400,
      }
  },
  PostButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "600px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: {
          left: '150px',
      },
  },
}));

const OwningPage = () => {
  const {
      intpage, 
      newUserIntPage, 
      newUserIntPageWithScroll, 
      profile,
      getPageInfo,
      getAdsforpage,
      fetchRelatedMonoPages,
      owningpageinfo,
      profiles,
  } = useContext(ApiContext);

  const { pageid } = useParams();
  const [count, setCount] = useState(0);
  const [postsforintpagebyscroll, setPostsforintpagebyscroll] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const loaderRef = useRef(null);
  const [noMoreData, setNoMoreData] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [postcount, setPostcount] = useState(0);
  const [monthview, setMonthview] = useState([]);
  const [femaleview, setFemaleview] = useState({});
  const [maleview, setMaleview] = useState({});
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
      // 初回ロード時にページ情報と広告を取得
      getPageInfo(pageid);
      getAdsforpage(pageid);
      fetchRelatedMonoPages(pageid);
      fetchData();
  }, [pageid]);

  useEffect(() => {
      console.log('pageId:', count);
      fetchData();
  }, [count]);

  const fetchData = async () => {
      setIsLoading(true);
      const result = await newUserIntPageWithScroll(pageid, count);
      setPostsforintpagebyscroll(prevItems => [...prevItems, ...result.res]);
      setIsLoading(false);
  };

  useEffect(() => {
      if (owningpageinfo) {
          setPostcount(owningpageinfo.post_count || 0);
          setMonthview(owningpageinfo.monthly_views || []);
          setFemaleview(owningpageinfo.gender_count?.female || {});
          setMaleview(owningpageinfo.gender_count?.male || {});
      }
  }, [owningpageinfo]);

  useEffect(() => {
      const options = {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
      };

      const observer = new IntersectionObserver(handleObserver, options);

      if (loaderRef.current) {
          observer.observe(loaderRef.current);
      }

      return () => {
          observer.disconnect();
      };
  }, []);

  const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting && !isLoading && !noMoreData) {
          setCount(prev => prev + 1);
      }
  };

  const openDialog = () => {
      setOpen(true);
  };

  const closeDialog = () => {
      setOpen(false);
  };

  const state = {
    labels: ['Feb', 'Feb', 'Mar', 'Apr', 'May' ,'Jun', 'Jul', 'Aug', 'Sep', 'Oct' , 'Nov', 'Dec'],
    series: [{
      name: '',
      data: [35, 66, 34, 56, 18 ,35, 66, 34, 56, 18 , 56, 18]
    },{
      name: '',
      data: [12, 34, 12, 11, 7 ,12, 34, 12, 11, 7 , 11, 7]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 250,
        stacked: true,
        toolbar: {
          show: false
        },    
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      legend: {
        show: false
      },
      fill: {
        opacity: 1
      },
    },
  };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <div className="main-content bg-white right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className={classes.PostButtonContainer}>
              <Tooltip title="Post Comment" placement="top-start">
                {profile.id ? (
                  <Fab
                    color="secondary"
                    className={classes.PostButton}
                    onClick={openDialog}
                  >
                    <AddComment />
                  </Fab>
                ) : (
                  <></>
                )}
              </Tooltip>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                <div className="row">
                  <div className="col-lg-6">
                    {intpage.img && <img src={intpage.img} alt="banner" className="w-100" />}
                  </div>
                  <div className="col-lg-6 ps-lg-5">
                    <h2 className="mt-3 display1-size d-block mb-2 text-grey-900 fw-700">{intpage.title}</h2>
                    <p className="font-xssss fw-500 text-grey-500 lh-26">{intpage.summary}</p>
                  </div>
                  <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss mt-4 alert-current mb-4">
                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-current mb-4">{t('OwningPage1')}</h2>
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                      <i className=" ti-check font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5"></i>
                      {intpage.goodpoint}
                    </h4>
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                      <i className="ti-close font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5"></i>
                      {intpage.badpoint}
                    </h4>
                  </div>
                  <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss mt-0 alert-current mb-0">
                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-current mb-4">{t('OwningPage2')}</h2>
                    {intpage.point1 ?
                      <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                        <i className="font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">1</i>
                        {intpage.point1}
                      </h4> : <></>}
                    {intpage.point2 ?
                      <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                        <i className="font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">2</i>
                        {intpage.point2}
                      </h4> : <></>}
                    {intpage.point3 ?
                      <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                        <i className="font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">3</i>
                        {intpage.point3}
                      </h4> : <></>}
                    {intpage.point4 ?
                      <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                        <i className="font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">4</i>
                        {intpage.point4}
                      </h4> : <></>}
                    {intpage.point5 ?
                      <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">
                        <i className="font-xssss btn-round-xs bg-current text-white position-absolute left-0 top-5">5</i>
                        {intpage.point5}
                      </h4> : <></>}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3 pe-2 ps-2">
              <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ background: `#f6f3ff` }}>
                <div className="card-body d-flex p-0">
                  <i className="btn-round-lg d-inline-block me-3 bg-secondary feather-lock font-md text-white"></i>
                  {monthview.length > 0 ?
                    <h4 className="text-secondary font-xl fw-700">{monthview[0].views}
                      <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">{t('OwningPage4')}</span></h4> :
                    <></>
                  }
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-3 pe-2 ps-2">
              <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ background: `#e2f6e9` }}>
                <div className="card-body d-flex p-0">
                  <i className="btn-round-lg d-inline-block me-3 bg-success feather-command font-md text-white"></i>
                  {femaleview ?
                    <h4 className="text-success font-xl fw-700">{femaleview | 0}<span className="fw-500 mt-0 d-block text-grey-500 font-xssss">{t('OwningPage5')}</span></h4> :
                    <></>
                  }
                </div>
              </div>
            </div> */}
            {/* <div className="col-lg-3 ps-2">
              <div className="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style={{ background: `#fff0e9` }}>
                <div className="card-body d-flex p-0">
                  <i className="btn-round-lg d-inline-block me-3 bg-warning feather-shopping-bag font-md text-white"></i>
                  {maleview ?
                    <h4 className="text-warning font-xl fw-700">{maleview | 0} <span className="fw-500 mt-0 d-block text-grey-500 font-xssss">{t('OwningPage6')}</span></h4> :
                    <></>
                  }
                </div>
              </div>
            </div> */}
            {/* {Array.isArray(intpage.files) && intpage.files.length > 0 && (
  <div className="row">
    {intpage.files.slice(0, 3).map((file) => (
      <DataDownload
        key={file.id}
        fileId={file.id}
        title={file.title}   // ダウンロード時の「ファイル名」に使いたいなら
      />
    ))}
  </div>
)} */}




            <div className="col-lg-12">
              <div className="card w-100 border-0 shadow-none pt-2 pb-2 pe-5 ps-5 rounded-xxl bg-lightblue2 mb-3">
                <div className="row">
                  <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss mt-4 alert-current mb-4">
                    <h2 className="fw-700 font-sm mb-3 mt-1 ps-1 text-current mb-4">{t('OwningPage7')}</h2>
                    <h4 className="font-xssss fw-500 text-grey-600 mb-3 pl-35 position-relative lh-24">{intpage.answer}</h4>
                  </div>
                </div>
              </div>
            </div>
            <Createpost
              isVisible={isOpen}
              postTo={intpage.id}
              profileData={profile}
              handleClose={closeDialog}
            />
            <br />
            {profiles && postsforintpagebyscroll.map((post, index) => (
              <span key={index}>
                <PostviewforPage
                  postData={post}
                  profileData={profiles.find(item => item.userProfile === Number(post.userPost))}
                  reposting={post.reposting}
                  repostingProfileData={profiles.find(item => item.userProfile === post.repostingFromUser)}
                />
              </span>
            ))}
            {postsforintpagebyscroll.length === 0 && <NoPostYet />}
            <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }}></div>
            {isLoading && (
              <div className="card-body p-0 mb-3">
                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                    <div className="stage">
                      <div className="dot-typing"></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Appfooter />
    </Fragment>
  )
}


export default OwningPage;
