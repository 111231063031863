import React, { Fragment, useEffect, useRef } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import { ApiContext } from "../context/ApiContext";
import Profiledetail from "../components/Profiledetail";
import UserpagePicture from "../components/UserpagePicture";
import Postview from "./Postview";
import Lightbox from "react-image-lightbox";
import { useCookies } from "react-cookie";
import LoginComponent from "../components/LoginComponent";
import SpecificUserpageAds from "../components/SpecificUserPageAds";
import AdvertisementView from "../components/AdvertisementView";
import ProfileRecommender from "../components/ProfileRecommender";
import NoPostYetforUser from "../components/NoPostYetforUser";
import SpecificUserpageDatas from "../components/SpecificUserPageDatas";
import { useTranslation } from 'react-i18next';
import { Tooltip, Fab} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Createpurepost from "../components/CreatePurepost";
import { AddComment } from "@material-ui/icons";
import { SnackbarContext } from "../context/SnackbarContext";
import HowToModal from "../components/HowToModal";
import DataModal from "../components/DataModal";

const useStyles = makeStyles((theme) => {
  return {
    PostButton: {
      backgroundColor: green[400],
      "&:hover": {
        backgroundColor: green[200],
      }
    },
    PostButtonContainer: {
      position: "fixed",
      width: "100%",
      zIndex: "999",
      left: "350px",
      bottom: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down('xs')]: {  // メディアクエリ
        left: '150px',
      },
    },
    DataButton: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      },
      DataButtonContainer: {
        position: "fixed",
        width: "100%",
        zIndex: "999",
        left: "350px",
        bottom: "135px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {  // メディアクエリ
          left: '150px',
        },
    },
    cssloadLoader2: {
      position: 'relative',  // 'absolute'から変更
      width: '30px',
      height: '30px',
      margin: '0 10px', // これにより左右の間隔を設定し、上下の間隔を0にします。
      perspective: '780px',
      left: '1px',
    },
    cssloadInner: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      borderRadius: '50%',
      '&.cssloadOne': {
        left: '0%',
        top: '0%',
        animation: '$cssloadRotateOne 4s linear infinite',
        borderBottom: '3px solid #fff0f5',
      },
      '&.cssloadTwo': {
        right: '0%',
        top: '0%',
        animation: '$cssloadRotateTwo 4s linear infinite',
        borderRight: '3px solid #fff0f5',
      },
      '&.cssloadThree': {
        right: '0%',
        bottom: '0%',
        animation: '$cssloadRotateThree 4s linear infinite',
        borderTop: '3px solid #fff0f5',
      },
    },
    '@keyframes cssloadRotateOne': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(-45deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateTwo': {
      '0%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(50deg) rotateY(10deg) rotateZ(360deg)',
      },
    },
    '@keyframes cssloadRotateThree': {
      '0%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(0deg)',
      },
      '100%': {
        transform: 'rotateX(35deg) rotateY(55deg) rotateZ(360deg)',
      },
    },
    bodyStyle: {
      background: 'linear-gradient(-45deg,#79AC78,#B0D9B1)',
      backgroundSize: '400% 400%',
      animation: '$gradient 10s ease infinite',
      // height: '100vh'
    },
    "@keyframes gradient": {
      "0%": {
        backgroundPosition: '0% 50%'
      },
      "50%": {
        backgroundPosition: '100% 50%'
      },
      "100%": {
        backgroundPosition: '0% 50%'
      }
    },
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
  
    },  
  };
});


const Userpage = () => {
  const { profile, monoposts, profiles, ads, userpostWithScroll, newUserIntUser } = useContext(ApiContext);
  const [cookies] = useCookies(["current-token"]);

  const { t } = useTranslation();
  const classes = useStyles();


  const { newSnack } = useContext(SnackbarContext);

  const [isShowDataModal, setShowDataModal] = useState(false);

  const handleDataClick = () => {
    if (!profile.userProfile) {
      newSnack("error", t('Header14'));
      return;
    }
    setShowDataModal(true);
  }

  const handleDataModalClose = () => {
    setShowDataModal(false);
  }

  // const listMonoPosts = posts.map((post, index) => (
  //   <>
  //     <Postview
  //       key={post.id}
  //       postData={post}
  //       profileData={profiles.find((item) => {
  //         return item.userProfile === post.userPost;
  //       })}
  //       reposting={post.reposting}
  //       repostingProfileData={profiles.find((item) => {
  //         return item.userProfile === post.repostingFromUser;
  //       })}
  //     />
  //     {
  //       index % 5 == 4 && parseInt(index / 5) < ads.length ?
  //         <AdvertisementView
  //           profile={getSpecificProfile(ads[parseInt(index / 5)].userId)}
  //           item={ads[parseInt(index / 5)]}
  //         /> :
  //         <></>
  //     }
  //   </>
  // ));
  const [isOpenModal, setIsOpenModal] = useState(false);
  
  const [isOpen, setOpen] = useState(false);
  // console.log(profile)
  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  const [isShowHowToModal, setShowHowToModal] = useState(false);
  const handleShowHowToModalClick = () => {
    setShowHowToModal(true)
  }
  const handleHowToModalClose = () => {
    setShowHowToModal(false);
  }

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      setShowHowToModal(true);
      localStorage.setItem('hasVisited', 'true');
    }
  }, []);

  const [count, setCount] = useState(0);
  const [postsbyscroll, setPostsbyscroll] = useState([]);
  const loaderRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);


  const fetchData = async () => {
    setIsLoading(true);
    const result = await userpostWithScroll(count, profile.userProfile);
    if (result && result.res) {  
        setPostsbyscroll(prevItems => count === 0 ? [...result.res, ...prevItems] : [...prevItems, ...result.res]);
    }
    setIsLoading(false);
};




  const handleObserver = (entities, observer) => {
    const target = entities[0];

    if (target.isIntersecting && !isLoading) {
        setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0
    };
  

    const observer = new IntersectionObserver(handleObserver, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (profile.userProfile) {  // profile.userProfile が定義されているかチェック
      console.log('pageId:' + count);
      fetchData();
    }
  }, [count, profile.userProfile]);
  
  


  return (
    <Fragment>
      <Header />
      <Leftnav />

      <div className="main-content right-chat-active">
      <HowToModal
        isVisible={isShowHowToModal}
        handleClose={handleHowToModalClose}
      />
      <DataModal
        isVisible={isShowDataModal}
        handleClose={handleDataModalClose}
        
      />
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left ps-2 pe-2">
            <div className="row">
            <div className={ classes.PostButtonContainer }>
                <Tooltip title="Post Comment" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={openDialog}
                    >
                      <AddComment />
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.PostButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                      <AddComment />
                    </Fab>
                  )}
                </Tooltip>
              </div>
              <div className={ classes.DataButtonContainer }>
                <Tooltip title="Post Data" placement="top-start">
                  {profile.id ? (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={handleDataClick}
                    >
                    <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  ) : (
                    <Fab
                      color="secondary"
                      className={classes.DataButton}
                      onClick={()=>newSnack("error", "投稿するにはログインしてください")}
                    >
                        <div className={classes.flexContainer}>
                        <div className={classes.cssloadLoader2}>
                          <div className={`${classes.cssloadInner} cssloadOne`}></div>
                          <div className={`${classes.cssloadInner} cssloadTwo`}></div>
                          <div className={`${classes.cssloadInner} cssloadThree`}></div>
                        </div>
                    </div>
                    </Fab>
                  )}
                </Tooltip>
              </div>
              <div className="col-xl-12 mb-3">
                <div className="card w-100 border-0 p-0 pt-10 mt-5 bg-white shadow-xss rounded-xxl">
                  <div className="card-body d-block  pt-1 pb-0 ps-2 pe-2">
                    {/* {profile.imgBackground ? (
                      <div className="row ps-2 pe-2">
                        <div className="col-sm-12 p-1">
                          <img
                            src={profile.imgBackground}
                            className="rounded-xxl w-100"
                            alt="post"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="row ps-2 pe-2">
                        <div className="col-sm-12 p-1">
                          <img
                            src="https://ow6r85j5w096clt.site/media/image/default_background.jpg"
                            className="rounded-xxl w-100"
                            alt="post"
                          />
                        </div>
                      </div>
                    )} */}
                  </div>
                  <div className="card-body p-0 position-relative">
                    {profile.id ? (
                      // <figure className='avatar position-absolute w100 z-index-1' style={{top:'-40px',left:'30px'}}>
                      // <img src={profile.img} alt="avater" className='float-right p-1 bg-white rounded-circle w-100'/>
                      // </figure>
                      <div>
                        {isOpenModal && (
                          <Lightbox
                            mainSrc={profile.img}
                            onCloseRequest={() => setIsOpenModal(false)}
                          />
                        )}
                        <div onClick={() => setIsOpenModal(true)}>
                          <figure
                            className="avatar position-absolute w100 z-index-1"
                            style={{ top: "-40px", left: "30px" }}
                          >
                            {profile.img ?
                            <img
                            src={profile.img}
                            alt="avater"
                            className="float-right p-1 bg-white rounded-circle w-100"
                          />:
                          <></>
                            }
                            
                          </figure>
                        </div>
                      </div>
                    ) : (
                      <figure
                        className="avatar position-absolute w100 z-index-1"
                        style={{ top: "-40px", left: "30px" }}
                      >
                        <img
                          src="https://ow6r85j5w096clt.site/media/image/サラリーマン.jpeg"
                          alt="avater"
                          className="float-right p-1 bg-white rounded-circle w-100"
                        />
                      </figure>
                    )}
                    {profile.nickName?
                    <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                    {profile.nickName}
                    <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                      {profile.created_on}{t('Userpage6')}
                    </span>
                    </h4>:
                    <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                    {t('Userpage1')}
                    <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">
                     XXXX-XX-XX{t('Userpage6')}
                    </span>
                    </h4>
                    }

                    <div className="position-absolute bottom-8 right-15">
                      {profile.id ?
                        <Link to="/settings">
                        <button className="pointer mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ls-3 d-inline-block rounded-xl  font-xsssss fw-700 ls-lg text-grey-600 d-flex">
                          <i className="feather-settings text-grey-500 mt-0  font-lg"></i>
                        </button>
                      </Link>:
                      <></>
                      }
                    </div>
                  </div>

                  <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
                    <ul
                      className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="active list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block active"
                          // to="#navtabs1"
                          to="/userpage"
                          data-toggle="tab"
                        >
                          {t('Userpage2')}
                        </Link>
                      </li>

                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to="/userpagebook"
                          data-toggle="tab"
                        >
                         {t('Userpage7')}
                        </Link>
                      </li>
                      


                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to={`/followingpagelist`}
                          data-toggle="tab"
                        >
                          
                          {t('Userpage3')}
                        </Link>
                      </li>

                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to="/likedposts"
                          data-toggle="tab"
                        >
                          
                          {t('Userpage4')}
                        </Link>
                      </li>
                      {/* <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to="/following"
                          data-toggle="tab"
                        >
                          フォロー
                        </Link>
                      </li>
                      <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to="/followed"
                          data-toggle="tab"
                        >
                          フォロワー
                        </Link>
                      </li> */}

                      {/* <li className="list-inline-item me-5">
                        <Link
                          className="fw-700 font-xssss text-grey-500 pt-3 pb-3 ls-1 d-inline-block"
                          to="/posteddata"
                          data-toggle="tab"
                        >
                          {t('Userpage5')}
                        </Link>
                      </li> */}
                      <li className="list-inline-item me-5">
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 pe-3">
                <Profiledetail />
                <Createpurepost
                isVisible={isOpen}
                profileData={profile}
                handleClose={closeDialog}
                />
                {/* <UserpagePicture /> */}
                <SpecificUserpageAds userId={profile.userProfile} />
                <SpecificUserpageDatas userId={profile.userProfile}/>

              </div>
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                {!profile.id ? (
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 me-3">
                    <LoginComponent />
                  </div>
                ) : (
                  <></>
                )}
                {/* {!profile.id ? (
                  <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 bg-current">
                    <ProfileRecommender />
                  </div>
                ) : (
                  <></>
                )} */}

                  { postsbyscroll ?
                    postsbyscroll.map((post, index) => (
                      <>
                        <Postview
                          key={post.id}
                          postData={post}
                          profileData={post.userPostProfile}
                          reposting={post.reposting}
                          repostingProfileData={post.repostingProfile || null}
                          commentedCounts={post.commentedCounts} 
                          repostedTimes={post.repostedTimes}     
                          likedTimes={post.likedTimes}
                          monopage = {post.reviewToPage}  
                        />
                        {index % 3 === 2 && parseInt(index / 3) < ads.length ? (
                          <AdvertisementView
                            profile={newUserIntUser(ads[parseInt(index / 3)].userId)}
                            item={ads[parseInt(index / 3)]}
                          />
                        ) : null}
                      </>
                    )) : null}

                <div ref={loaderRef} style={{ height: '20px', margin: '0 auto' }} className="mb-5"></div>
                {isLoading ?
                <div className="card-body p-0 mb-3">
                <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                  <div
                    className="snippet mt-2 ms-auto me-auto"
                    data-title=".dot-typing"
                  >
                    <div className="stage">
                      <div className="dot-typing"></div>
                    </div>
                  </div>
                </div>
              </div>

              :
              <></>
                }
                {
                  profile && profile.id && postsbyscroll.length == 0 ? (
                    <NoPostYetforUser/>
                  ) : (
                    <></>
                  )
                }



                {/* {listMonoPosts} */}
                {/* {listMonoPosts.length == 0 ?
                <NoPostYetforUser/>:
                <></>
                } */}
                {/* <div className="card-body p-0 mb-3">
                  <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                    <div
                      className="snippet mt-2 ms-auto me-auto"
                      data-title=".dot-typing"
                    >
                      <div className="stage">
                        <div className="dot-typing"></div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="p-4 mb-3">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
};

export default Userpage;
